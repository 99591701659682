import React, { useState, useEffect } from 'react'
import { OM_API_URL } from './BASE_URL';
import axios from 'axios';

import parse from 'html-react-parser';

import { toast } from 'react-hot-toast';
import Welcome from './Welcome';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {

    useEffect(() => {
        getCmsList()
    }, [])

    const [about, setAbout] = useState('')
    const navigate = useNavigate();
    console.log(about, "%%")

    const getCmsList = () => {
        const myurl = `${OM_API_URL}/guru/get-cms`;

        axios({
            method: "post",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status === true) {
                    setAbout(response?.data?.data?.guide_cms?.about_us)
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);

            });
    };

    return (
        <div>
            <div class="rd-dashboard-content">
                <Sidebar />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Welcome />
                        <div class="rd-page-content" data-scrollbar>
                            <section className="about-section">
                                <div className="container">
                                    <div className="about-section-content">
                                        <div className="row">
                                            <div className="col col-12 col-md-6">
                                                <div className="about-section-img">
                                                    <img src="assets/images/about-mob.png" alt="About" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6">
                                                <div className="about-section-content">
                                                    <h4 className="section-title">Welcome to OM At Home</h4>
                                                    <div className="about-section-description">
                                                        <>
                                                            {parse(about)}
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AboutUs