import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Welcome from './Welcome'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { OM_API_URL } from './BASE_URL'
import { toast } from 'react-hot-toast'
import moment from 'moment'

const DonationHistory = () => {
    moment.locale('en')
    const nav = useNavigate();
    const [Loading,setLoading]=useState(true);
    const [donations,setDonations]=useState([]);
    useEffect(()=>{
        getDonationHistory()
    // eslint-disable-next-line
    },[]);
    const getDonationHistory = () =>{
        axios({
            method: 'get',
            url: `${OM_API_URL}/guru/get-donation-history`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',Authorization:`Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
        }).then((response) => {
            if(response.data.status){
                setDonations(response.data.data);
                console.log(donations)
            }
            else{
                toast.error(response.data.message)
            }
            setLoading(false)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
            if(err.response.status===401){
                localStorage.removeItem('OM_GURU_TOKEN');
                localStorage.removeItem('OM_GURU_NAME');
                localStorage.removeItem('OM_GURU_EMAIL');
                localStorage.removeItem('OM_GURU_IMAGE');
                nav('/login');
            } 
            // setError({ ...error, password_err: err.response.data.message })
        })
    }
  return (
    <div>
        
    <div class="rd-dashboard-content">
       
       <Sidebar />
        <main class="rd-site-content">
            <div class="rd-site-content__inner">
                <Welcome />
                <div class="rd-page-content" data-scrollbar>
                                <div class="rd-notiofication-head">
                                    <h3 class="page-title">Donation</h3>
                                </div>
                    <div class="rd-content-area">
                        <div class="rd-customers-table">
                            <div class="table-responsive" data-scrollbar>
                                <table class="table table-borderless table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Image</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Amount </th>
                                            <th scope="col">Event</th>
                                            <th scope="col">Date </th>
                                            <th scope="col">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Loading ? <tr><td colSpan={6}><h3>Loading</h3></td></tr>:
                                        donations.map((donation)=>{
                                            return (
                                                <tr>
                                                    <td>
                                                        <span class="donation-img">
                                                            <img src={donation.image} alt="donation-img"
                                                                class="img-fluid" onError={({currentTarget})=>{currentTarget.onerror=null;currentTarget.src = '/assets/images/event-img.png'}}/>
                                                        </span>
                                                    </td>
                                                    <td>{donation.user || "-"}</td>
                                                    <td><span class="donation-amount">${donation.amount}</span></td>
                                                    <td>{donation.program_title || "Donation"}</td>
                                                    <td>{moment(new Date(donation.createdAt)).format('DD MMM, YYYY')}</td>
                                                    <td>{moment(new Date(donation.createdAt)).format('hh:mm a')}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- <div class="rd-table-pagination">
                        <a href="#" class="rd-table-pagination-arrow">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_167_1136)">
                                    <path
                                        d="M5.02568 11.2496L14.4693 20.69C14.8837 21.1034 15.555 21.1034 15.9704 20.69C16.3848 20.2767 16.3848 19.6053 15.9704 19.192L7.2759 10.5006L15.9694 1.80919C16.3838 1.39586 16.3838 0.724501 15.9694 0.310122C15.555 -0.103211 14.8826 -0.103211 14.4682 0.310122L5.02463 9.7505C4.61662 10.1596 4.61662 10.8415 5.02568 11.2496Z"
                                        fill="#2D2D2D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_167_1136">
                                        <rect width="21" height="21" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <p class="rd-table-pagination-text">page 1 of 10</p>
                        <a href=" #" class="rd-table-pagination-arrow">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_167_1133)">
                                    <path
                                        d="M15.9743 11.2496L6.53072 20.69C6.11634 21.1034 5.44498 21.1034 5.02955 20.69C4.61517 20.2767 4.61517 19.6053 5.02955 19.192L13.7241 10.5006L5.0306 1.80919C4.61622 1.39586 4.61622 0.724501 5.0306 0.310122C5.44498
                            -0.103211 6.11739 -0.103211 6.53176 0.310122L15.9754 9.7505C16.3834 10.1596 16.3834 10.8415 15.9743 11.2496Z"
                                        fill="#2D2D2D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_167_1133">
                                        <rect width="21" height="21" fill="white" transform="matrix(-1 0 0 1 21 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </div> --> */}
                </div>
            </div>
        </main>
    </div>
    </div>
  )
}

export default DonationHistory