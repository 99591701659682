
import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Welcome from './Welcome'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import axios from 'axios';
import { OM_API_URL } from './BASE_URL'


// import axios from 'axios'
// import { OM_API_URL } from './BASE_URL'
// import { toast } from 'react-hot-toast'

const LiveStreaming = () => {

  const nav = useNavigate()
  // const location = useLocation();
  // const [count, setCount] = useState(0);


  // const nav = useNavigate();
  const [Loading,] = useState(false);
  const params = useParams()

  const location = useLocation();
  // const Live = location.state();
  // console.log(Live, "Live ")


  // const token = process.env.REACT_APP_ZEGOCLOUD_TOKEN;
  const appID = Number(process.env.REACT_APP_ZEGOCLOUD_APPID);
  console.log(appID)
  const serverSecret = process.env.REACT_APP_ZEGOCLOUD_SERVER_SECRET;
  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, params.event_id, localStorage.getItem('OM_GURU_EMAIL'), localStorage.getItem('OM_GURU_NAME'));
  // const role = ZegoUIKitPrebuilt.Host;
  let myMeeting = async (element) => {
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      showPreJoinView: false,
      showScreenSharingButton: false,
      
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role: ZegoUIKitPrebuilt.Host,
          // liveStreamingMode: ZegoUIKitPrebuilt.LiveStreamingMode.LiveStreaming
        },
      },

      onLiveStart() {


        if (localStorage.getItem("LIVE_TOKEN") === null) {
          axios({
            method: 'post',
            url: `${OM_API_URL}/guru/live-guru`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
          }).then((response) => {
            console.log(response?.data, "@@")
            if (response.data.status) {
              console.log(response.data.data.id, "##")
              localStorage.setItem("LIVE_TOKEN", response?.data?.data?.id)
            }
          }).catch((err) => {
            console.log(err)
          })
        }
        alert("start");

      },

      onLiveEnd() {

        if (localStorage.getItem("LIVE_TOKEN") !== null) {
          const bodyFormData = new URLSearchParams();
          bodyFormData.append('live_guru_id', localStorage.getItem('LIVE_TOKEN'));

          axios({
            method: 'post',
            url: `${OM_API_URL}/guru/live-guru`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
          }).then((response) => {
            console.log(response, "!!!@@@##$$$%%%%")
            localStorage.removeItem("LIVE_TOKEN")
          }).catch((err) => {
            console.log(err);
          })
        }
        alert("onLiveEnd")
      },

      onLeaveRoom() {

        if (localStorage.getItem("LIVE_TOKEN")) {
          const bodyFormData = new URLSearchParams();
          bodyFormData.append('live_guru_id', localStorage.getItem('LIVE_TOKEN'));

          axios({
            method: 'post',
            url: `${OM_API_URL}/guru/live-guru`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
          }).then((response) => {
            console.log(response, "!!!@@@##$$$%%%%")
            localStorage.removeItem("LIVE_TOKEN")
            nav('/upcoming-events')
          }).catch((err) => {
            console.log(err);
          })
        } else {
          nav('/upcoming-events')
        }
      }

    });
  };
  // setLoading(false)

  return (
    <div>
      <div className="rd-dashboard-content">
        <Sidebar />
        <main className="rd-site-content">
          <div className="rd-site-content__inner">
            <Welcome />
            <div className="rd-page-content" data-scrollbar>

              <div className="om-event-conetnt">
                <div className="row">
                  {
                    Loading === true ?
                      <h3 style={{ textAlign: "center" }}>Loading...</h3>
                      :
                      <div
                        className="myCallContainer"
                        ref={myMeeting}
                        style={{ width: '100vw', height: '100vh' }}
                      ></div>

                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default LiveStreaming