import React, { useState } from 'react'

import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Welcome from './Welcome';
import axios from 'axios';

import { OM_API_URL } from './BASE_URL';


import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

import { Modal } from 'react-bootstrap';


const Contact = () => {

    const [contact, setContact] = useState({ name: "", email: "", message: "", number: "" });
    const [error, setError] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate();

    const handleSend = (e) => {
        e.preventDefault();
        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('name', contact.name);
            bodyFormData.append('email', contact.email);
            bodyFormData.append("number", contact.number)
            bodyFormData.append('message', contact.message);

            axios({
                method: 'post',
                url: `${OM_API_URL}/guru/contact-us`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
            }).then((response) => {
                if (response?.data?.status === true) {
                    setContact(response?.data?.data)
                    // toast.success(response?.data?.message);
                    setContact({ name: "", email: "", number: "", message: "" })
                    setShow(true);
                    setTimeout(() => {
                        handleClose();
                    }, 4000);
                    

                } else {
                    toast.error(response?.data?.message)
                }
            }).catch((err) => {
                console.log('Errors', err);
                toast.error('Something went wrong...!');
            })

        }

    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!contact.name) {
            isValid = false;
            err['name_err'] = "Please enter your name"
        }
        if (!contact.email) {
            isValid = false;
            err['email_err'] = "Please enter your email address"
        } else if (typeof contact.email !== "undefined") {
            let lastAtPos = contact.email.lastIndexOf('@');
            let lastDotPos = contact.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contact.email.indexOf('@@') === -1 && lastDotPos > 2 && (contact.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Please enter valid email address";
            }
        }

        if (!contact.number) {
            isValid = false;
            console.log(contact.number, "number")
            err['number_err'] = "Please enter a mobile number"
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(contact.number)) {
                isValid = false;
                err['number_err'] = "Please enter a valid mobile number";
            }
        }

        if (!contact.message) {
            isValid = false;
            err['message_err'] = "Please enter your message"
        }
        setError(err);
        return isValid;
    }



    return (
        <div>
            <div class="rd-dashboard-content">
                <Sidebar />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Welcome />
                        <div class="rd-page-content" data-scrollbar>
                            <div class="rd-notiofication-head">
                                <form>
                                    <div className="row">
                                        <div className="col col-12 col-lg-7">
                                            <div className="rd-content-area rd-invite-user">
                                                <h1 className="rd-login-form-title">Contact Us</h1>
                                                <h1 className='rd-login-form-title contact-us' style={{ fontSize: "14px" }}><b>Note</b>: We can also be reached at <a href='mailto:hello@omathomeapp.com'>hello@omathomeapp.com</a></h1>
                                                <div className="row">
                                                    <div className="col col-12">
                                                        <label htmlFor="inputUsername" className="form-label">Name</label>
                                                        <input type="text" className="form-control" name='name' value={contact.name} id="inputUsername" onChange={handleChange} />
                                                        <span className='error'>{error.name_err}</span>
                                                    </div>
                                                    <div className="col col-12">
                                                        <label htmlFor="inputUsername" className="form-label">Email</label>
                                                        <input type="email" className="form-control" name='email' value={contact.email} id="inputUsername" onChange={handleChange} />
                                                        <span className='error'>{error.email_err}</span>
                                                    </div>

                                                    <div className='col col-12'>
                                                        <label htmlFor="inputUsername" className="form-label">Number</label>
                                                        <PhoneInput
                                                            containerClass='login-social-links'
                                                            inputClass="form-control"
                                                            inputExtraProps={{
                                                                name: "number",
                                                                required: true,
                                                                autoFocus: true
                                                            }}

                                                            inputStyle={{
                                                                border: "1px solid black",
                                                                height: "auto",

                                                                color: "#7C7C7C",
                                                                borderRadius: "0",
                                                                width: "100%"
                                                            }}
                                                            country={"us"}
                                                            buttonStyle={{ minWidth: "30px" }}
                                                            name="number"
                                                            defaultCountry={"us"}
                                                            value={contact.number}
                                                            onChange={(val, countryData) => {
                                                                const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                                // const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                                let formattedValue = numericValue;
                                                                if (numericValue.startsWith(countryData.dialCode)) {
                                                                    formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                                }
                                                                console.log(formattedValue)
                                                                setContact({ ...contact, number: formattedValue });
                                                            }}
                                                        />
                                                        <span className='error'>{error.number_err}</span>
                                                    </div>

                                                    <div className="col col-12">
                                                        <label className="form-label">Message</label>
                                                        <textarea className={error.message_err ? "form-control" : "form-control"} cols="30" rows="3" name="message" onChange={handleChange} value={contact.message}></textarea>
                                                        <span className='error'>{error.message_err}</span>
                                                    </div>
                                                </div>
                                                <div className="rd-heading-btn rd-invite-btn">
                                                    <button type='button' onClick={handleSend} className="btn btn-dark">Send</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='contact-us-modal' style={{ padding: 0 }}>
                    <div>
                        <img src='assets/images/guru-contact.png' alt="" className="img-fluid conatct-us-img" />
                        <div style={{ textAlign: "center" }}>
                            <h1 className='contact-heading'>Congratulations</h1>
                            <p className='contact-text'>Your form submitted successful</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Contact