import { Route, Routes, useLocation } from 'react-router-dom';
import DonationHistory from './components/DonationHistory';
import EnterEmail from './components/EnterEmail';
import Login from './components/Login';
import Notification from './components/Notification';
import Otp from './components/Otp';
import PastEvent from './components/PastEvent';
import ResetPassWord from './components/ResetPassWord';
import SignUp from './components/SignUp';
import UpcomingEvent from './components/UpcomingEvent';
// import Welcome from './components/Welcome';
import ForgotPassword from './components/ForgotPassword';
import { Toaster } from 'react-hot-toast';
import LiveStreaming from './components/LiveStreaming';
import { useEffect, useState } from 'react';
import LikeList from './components/LikeList';
import UserLike from './components/UserLike';
import TermsCondition from './components/TermsCondition';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';




function App() {
  const [isLeftFromRoom, setisLeftFromRoom] = useState(false)
  const location = useLocation()
  useEffect(() => {
    // execute on location change
    if (location.pathname.includes('/live-streaming')) {
      setisLeftFromRoom(true)
    }
  }, [location]);
  return (
    <div className="App">
      <Toaster position="top-left" />

      <Routes>

        {/* <Route path='/' exact element={<Welcome />} /> */}

        <Route path='*' exact element={<Login />} />

        <Route path='/login' exact element={<Login />} />
        <Route path='/sign-up' exact element={<SignUp />} />
        <Route path='/reset-password' exact element={<ResetPassWord />} />
        <Route path='/enter-email' exact element={<EnterEmail />} />

        <Route path='/donation-history' exact element={<DonationHistory />} />

        <Route path='/like-history' exact element={<LikeList />} />
        <Route path='/user-likes' exact element={<UserLike />} />

        <Route path='/otp-verification' exact element={<Otp />} />

        <Route path='/forgot-password' exact element={<ForgotPassword />} />

        <Route path='/notification' exact element={<Notification />} />

        {/* CMS */}
        <Route path='/terms-and-conditions' exact element={<TermsCondition />} />
        <Route path='/about-us' exact element={<AboutUs />} />
        <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />

        <Route path='/contact-us' exact element={<Contact />} />
 
        <Route path='/upcoming-events' exact element={<UpcomingEvent />} />
        <Route path='/past-events' exact element={<PastEvent />} />

        <Route path='/live-streaming/:event_id' exact element={<LiveStreaming state={isLeftFromRoom} />} />

      </Routes>
    </div>
  );
}

export default App;
