import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';

import $ from 'jquery';

const Welcome = () => {
    const nav = useNavigate();
    useEffect(() => {

        if (!localStorage.getItem('OM_GURU_TOKEN')) {
            localStorage.removeItem('OM_GURU_TOKEN')
            localStorage.removeItem('OM_GURU_IMAGE')
            localStorage.removeItem('OM_GURU_EMAIL')
            localStorage.removeItem('OM_GURU_NAME')
            toast.error("Unauthorized")
            nav('/login');
        }

    }, []);

    useEffect(() => {
        $(".navbar-toggler").click(function () {
            $(".navbar-toggler-icon").toggleClass("navbar-toggler-icon-show")
        })
        // hamburger Effect End

        // Open Side Menu Start
        $(".navbar-toggler").click(function () {
            $(".rd-site-header").toggleClass("rd-site-header-show")
        })
        // Open Side Menu End

        // Navbar Color Start
        $(".home-header .navbar-toggler").click(function () {
            $(".navbar-toggler-icon").toggleClass("navbar-toggler-color")
        })
        // Navbar Color End

        // Open Menu Start
        $(".home-header .navbar-toggler").click(function () {
            $("body").toggleClass("open-menu")
        })
        // Open Menu End


        // End Session Start
        $('#end_session_btn').on('click', function () {
            $('.end-session-popup').addClass('active');
        });
        $('#cancle_session , #end_session').on('click', function () {
            $('.end-session-popup').removeClass('active');
        });
        // eslint-disable-next-line
    }, []);



    return (
        <div>
            <div className="rd-page-head">
                <button className="navbar-toggler" type="button">
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="rd-page-head-title">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_397_15659" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0"
                            width="36" height="36">
                            <rect width="36" height="36" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_397_15659)">
                            <path
                                d="M13.5 30C12.675 30 11.969 29.7065 11.382 29.1195C10.794 28.5315 10.5 27.825 10.5 27C10.5 26.175 10.794 25.4685 11.382 24.8805C11.969 24.2935 12.675 24 13.5 24C14.325 24 15.0315 24.2935 15.6195 24.8805C16.2065 25.4685 16.5 26.175 16.5 27C16.5 27.825 16.2065 28.5315 15.6195 29.1195C15.0315 29.7065 14.325 30 13.5 30ZM22.5 30C21.675 30 20.969 29.7065 20.382 29.1195C19.794 28.5315 19.5 27.825 19.5 27C19.5 26.175 19.794 25.4685 20.382 24.8805C20.969 24.2935 21.675 24 22.5 24C23.325 24 24.0315 24.2935 24.6195 24.8805C25.2065 25.4685 25.5 26.175 25.5 27C25.5 27.825 25.2065 28.5315 24.6195 29.1195C24.0315 29.7065 23.325 30 22.5 30ZM13.5 21C12.675 21 11.969 20.706 11.382 20.118C10.794 19.531 10.5 18.825 10.5 18C10.5 17.175 10.794 16.4685 11.382 15.8805C11.969 15.2935 12.675 15 13.5 15C14.325 15 15.0315 15.2935 15.6195 15.8805C16.2065 16.4685 16.5 17.175 16.5 18C16.5 18.825 16.2065 19.531 15.6195 20.118C15.0315 20.706 14.325 21 13.5 21ZM22.5 21C21.675 21 20.969 20.706 20.382 20.118C19.794 19.531 19.5 18.825 19.5 18C19.5 17.175 19.794 16.4685 20.382 15.8805C20.969 15.2935 21.675 15 22.5 15C23.325 15 24.0315 15.2935 24.6195 15.8805C25.2065 16.4685 25.5 17.175 25.5 18C25.5 18.825 25.2065 19.531 24.6195 20.118C24.0315 20.706 23.325 21 22.5 21ZM13.5 12C12.675 12 11.969 11.706 11.382 11.118C10.794 10.531 10.5 9.825 10.5 9C10.5 8.175 10.794 7.469 11.382 6.882C11.969 6.294 12.675 6 13.5 6C14.325 6 15.0315 6.294 15.6195 6.882C16.2065 7.469 16.5 8.175 16.5 9C16.5 9.825 16.2065 10.531 15.6195 11.118C15.0315 11.706 14.325 12 13.5 12ZM22.5 12C21.675 12 20.969 11.706 20.382 11.118C19.794 10.531 19.5 9.825 19.5 9C19.5 8.175 19.794 7.469 20.382 6.882C20.969 6.294 21.675 6 22.5 6C23.325 6 24.0315 6.294 24.6195 6.882C25.2065 7.469 25.5 8.175 25.5 9C25.5 9.825 25.2065 10.531 24.6195 11.118C24.0315 11.706 23.325 12 22.5 12Z"
                                fill="#fff" />
                        </g>
                    </svg>
                    <h1 className="rd-dashboard-logged-name">Guide Dashboard</h1>
                </div>
                <div className="rd-heading-right">
                    <Link to="/notification" className="rd-notification-link">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.2375 17.575L23.825 15.225C23.5125 14.7125 23.2375 13.725 23.2375 13.125V10.7875C23.2375 6.25 19.55 2.5625 15.025 2.5625C10.4875 2.575 6.80001 6.25 6.80001 10.7875V13.1125C6.80001 13.7125 6.52501 14.7 6.22501 15.2125L4.81251 17.5625C4.27501 18.475 4.15001 19.5125 4.48751 20.4125C4.82501 21.325 5.58751 22.05 6.58751 22.375C7.93751 22.825 9.30001 23.15 10.6875 23.3875C10.825 23.4125 10.9625 23.425 11.1 23.45L11.65 23.525C11.975 23.575 12.3 23.6125 12.6375 23.6375C14.2132 23.7861 15.7993 23.7861 17.375 23.6375C17.6625 23.6125 17.95 23.5875 18.225 23.55C18.45 23.525 18.675 23.5 18.9 23.4625C19.0375 23.45 19.175 23.425 19.3125 23.4C20.7125 23.175 22.1 22.825 23.45 22.375C23.917 22.2189 24.3434 21.9606 24.698 21.6189C25.0526 21.2773 25.3266 20.8609 25.5 20.4C25.85 19.4625 25.75 18.4375 25.2375 17.575ZM15.9375 12.5C15.9375 12.752 15.8374 12.9936 15.6593 13.1718C15.4811 13.3499 15.2395 13.45 14.9875 13.45C14.7356 13.45 14.4939 13.3499 14.3158 13.1718C14.1376 12.9936 14.0375 12.752 14.0375 12.5V8.625C14.0375 8.37304 14.1376 8.13141 14.3158 7.95325C14.4939 7.77509 14.7356 7.675 14.9875 7.675C15.2395 7.675 15.4811 7.77509 15.6593 7.95325C15.8374 8.13141 15.9375 8.37304 15.9375 8.625V12.5ZM18.5375 25.0125C18.2746 25.7402 17.7941 26.3693 17.1612 26.8144C16.5283 27.2594 15.7737 27.4988 15 27.5C14.0125 27.5 13.0375 27.1 12.35 26.3875C11.95 26.0125 11.65 25.5125 11.475 25C11.6375 25.025 11.8 25.0375 11.975 25.0625C12.2625 25.1 12.5625 25.1375 12.8625 25.1625C13.575 25.225 14.3 25.2625 15.025 25.2625C15.7375 25.2625 16.45 25.225 17.15 25.1625C17.4125 25.1375 17.675 25.125 17.925 25.0875L18.5375 25.0125Z"
                                fill={window.location.pathname.match(/\/notification/) ? "black" : "white"} />
                        </svg>
                    </Link>
                    <div className="dropdown profile-dropdown">
                        <span id="dropdownMenuProfile" data-bs-toggle="dropdown" aria-expanded="false">


                            <img src={localStorage.getItem('OM_GURU_IMAGE')} style={{ width: "40px", height: "40px", borderRadius: "100%" }} alt="heading-profile" className="img-fluid" />


                            <span>{localStorage.getItem('OM_GURU_EMAIL')}</span>
                        </span>
                        {/* <!-- <ul className="dropdown-menu" aria-labelledby="dropdownMenuProfile">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul> --> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome