// import moment from 'moment-timezone'
import React, { useState } from 'react'
// import PhoneInput from 'react-phone-input-2'
import { Link, useNavigate } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { OM_API_URL } from './BASE_URL'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// import moment from 'moment'
import { TextField } from '@material-ui/core';

import 'moment/locale/de';
const SignUp = () => {
  const nav = useNavigate();
  const [formData,setFormData] = useState({
    name:"",
    email:"",
    mobileno:"",
    // dob:null,
    password:"",
    cpassword:"",
    image:"",
    bio:"",
    // facebook:"",
    // instagram:"",
    // twitter:"",
    fcm_token:"-",
    timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [passwordType,setPasswordType] = useState("password");
  const [cpasswordType,setCPasswordType] = useState("password");

  const [img,setImg] = useState({
    src:"",
    alt:"",
  })
  const [error,setError]=useState({});
  const [disable,setDisable] = useState(false)

  const handleChange = (e) => {
    console.log(e)
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleImage = (e) => {
    if (e?.target?.files[0]) {
      setImg({
        src: URL.createObjectURL(e?.target?.files[0]),
        alt: e?.target?.files[0].name,
      });
      setDisable(true);
      const myurl = `${OM_API_URL}/upload-image`;
      var bodyFormData = new FormData();
      bodyFormData.append("image", e?.target?.files[0]);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
      })
        .then((result) => {
          console.log("=====", result);

          if (result?.data?.status === true) {
            //console.log('Success:=====',result?.data?.data?.filepath_url);
            setFormData({...formData,image:result?.data?.data?.url});
            setImg({alt:result?.data?.data?.filepath_url,src:result?.data?.data?.url})
            setDisable(false);
          } else {
            console.log("Error:=====");
            // console.log('Error:=====', result?.data);
            setImg({alt:"",src:""})
            setFormData({...formData,image:""});
          }
        })
        .catch((error) => {
          console.log(error);
          //console.log('Errors', error);
          setImg({alt:"",src:""})
          setFormData({...formData,image:""});
          setDisable(false);
          toast.error("Unable to upload image");
        });
    }
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    if (validation()) {
      const bodyFormData = new URLSearchParams(formData);
      
        setDisable(true);
        axios({
            method: 'post',
            url: `${OM_API_URL}/guru/sign-up`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if (response?.data?.status === true) {
                toast.success(response.data.message);
                nav('/otp-verification', { state: { email: formData.email, type: 'signup' } })
            }
            else{
              toast.error(response.data.message);
            }
          setDisable(false);
          
        }).catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          setDisable(false);
        })
    }
  }

  const validation = () => {
    let isValid = true;
    let err = {};

    if (!formData.name) {
        isValid = false;
        err['name_err'] = "Please enter name"
    }

    if (!formData.email) {
        isValid = false;
        err['email_err'] = "Please enter email"
    }
    else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(formData.email))) {
        isValid = false;
        err["email_err"] = "Email is not valid";
    }
    if (!formData.mobileno) {
        isValid = false;
        err['mobile_err'] = "Please enter a mobile number";
    }

  //   if (!formData.dob) {
  //     isValid = false;
  //     err['dob_err'] = "Please select date of birth";
  // }

    if (!formData.bio) {
        isValid = false;
        err['bio_err'] = "Please enter a bio";
    }

    if (!formData.image) {
      isValid = false;
      err['image_err'] = "Please select an image";
    }

    // if(formData.facebook || formData.twitter || formData.instagram){
    //   // eslint-disable-next-line
    //   if(formData.facebook && !/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/.test(formData.facebook) ){
    //     isValid = false;
    //     err['facebook_err'] = "Please enter valid facebook url";
        
    //   } 
    //   if(formData.instagram && !/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/im.test(formData.instagram) ){
    //     isValid = false;
    //     err['instagram_err'] = "Please enter valid instagram url";
    //   }

    //   if(formData.twitter && !/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(formData.twitter) ){
    //     isValid = false;
    //     err['twitter_err'] = "Please enter valid twitter url";
    //   }
    // }
    // else{
    //   isValid = false;
    //   err['atleast_one_social_err'] = "Please enter atleast one social media";
    // }


    if (!formData.password) {
      isValid = false;
      err['password_err'] = "Please enter password"

    } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
      isValid = false;
      err['password_err'] = "Password must be minimum eight characters, at least one letter, one number and one special character";
    }

    if (!formData.cpassword) {
      isValid = false;
      err['cpassword_err'] = "Please enter confirm password"
    }
    else if(formData.password!==formData.cpassword){
      isValid = false;
      err['cpassword_err'] = "Password does not match"
    }
    // if (!formData.terms) {
    //     isValid = false;
    //     err['terms_err'] = "Please select the terms & conditions"
    // }

    setError(err);
    return isValid;
  }
  return (
    <div>
      <main className="rd-site-content">
        <section className="rd-login-section">
          <div className="row g-0">
            <div className="col col-12 col-md-12 col-lg-6">
              <div className="rd-login-content om-signup-content">
                <Link to="index.html" className="rd-login-logo">
                  <span className="rd-login-logo__img">
                    <img src="assets/images/logo.svg" alt="logo" className="img-fluid om-logo" />
                  </span>
                </Link>
                {/* <div className="rd-login-form-content" data-scrollbar> */}
                <ScrollArea speed={1}
                    className="rd-login-form-content"
                    contentClassName="content"
                    horizontal={false}
                    smoothScrolling={true}
                >
                  <div className="rd-login-form-heading">
                    <h1 className="rd-login-form-title">Sign-Up</h1>
                    <p className="rd-login-form-description">Personal formData</p>
                  </div>
                  <div className="rd-login-form">

                    <form className="row" autocomplete="off" onSubmit={handleSignUp}>
                      <div className="col-md-12 col-lg-12">
                        <label for="inputName" className="form-label">Name</label>
                        <input type="text" className="form-control" id="inputName" name='name' value={formData.name} onChange={handleChange}  />
                        <span className='error'>{error.name_err}</span>
                      </div>
                      <div className="col-md-12">
                        <label for="inputEmail" className="form-label">Email Address </label>
                        <input type="email" className="form-control" id="inputEmail" name='email' value={formData.email} onChange={handleChange} />
                        <span className='error'>{error.email_err}</span>
                      </div>

                      <div className="col-md-12">
                        <label for="inputEmail" className="form-label">Mobile Number</label>
                        {/* <input type="text" className="form-control" id="inputPhone" onKeyPress={(event)=>{handleNumberKeyPress(event)}}/> */}
                        <PhoneInput
                          containerClass='login-social-links'
                          inputClass="form-control"
                                inputExtraProps={{
                                    name: "number",
                                    required: true,
                                    autoFocus: true
                                }}
                                
                                inputStyle={{
                                  border:"1px solid #7C7C7C",
                                  height:"auto",
                                
                                  color:"#7C7C7C",
                                  borderRadius:"0",
                                  width:"100%"
                                }}
                                country={"us"}
                                buttonStyle	={{minWidth:"30px"}}
                                name="number"
                                defaultCountry={"us"}
                                value={formData.mobileno}
                                onChange={(val, countryData) => {
                                    const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                    // const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                    let formattedValue = numericValue;
                                    if (numericValue.startsWith(countryData.dialCode)) {
                                        formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                    }
                                    console.log(formattedValue)
                                    setFormData({ ...formData, mobileno: formattedValue });
                                }}
                        />
                        <span className='error'>{error.mobile_err}</span>
                      </div>
                      
                      {/* <div className="col-md-12"> */}
                        {/* <label for="inputDob" className="form-label">Date of Birth</label> */}
                        {/* <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us" >
                          <DatePicker  
                            renderInput={(params) => (
                              <TextField {...params} style={{border:"none"}}/>
                            )}
                            format="DD-MM-YYYY"
                          
                          views={['year', 'month', 'day']} className="form-control" id="inputDob" name='dob' value={formData.dob} onChange={(value)=>{setFormData({ ...formData, dob: value })}} />
                        </LocalizationProvider> */}
                        {/* <input type="date" className="form-control" id="inputDob" name='dob' value={formData.dob} onChange={handleChange} /> */}
                        {/* <span className='error'>{error.dob_err}</span> */}
                      {/* </div> */}

                      <div className="col-md-12">
                        <label for="inputEmail" className="form-label">Photo to document</label>
                          <div className="signup-upload-license">
                          <label>
                            {img.src==="" ?
                            <>
                              <span>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1346_15732)">
                                    <path
                                      d="M20.0188 6.7002C19.7622 5.17522 19.0071 3.78349 17.8523 2.72735C16.5691 1.55276 14.901 0.90625 13.1638 0.90625C11.8214 0.90625 10.5136 1.2912 9.39328 2.01668C8.46052 2.61877 7.68569 3.43309 7.13788 4.39052C6.90099 4.3461 6.65423 4.32143 6.40746 4.32143C4.30999 4.32143 2.60241 6.02901 2.60241 8.12648C2.60241 8.39792 2.63202 8.65949 2.68137 8.91612C1.32418 9.90317 0.5 11.4923 0.5 13.1851C0.5 14.5521 1.00833 15.8797 1.93615 16.9309C2.88865 18.0068 4.14713 18.6434 5.48951 18.7175C5.50432 18.7175 5.51419 18.7175 5.529 18.7175H9.77329C10.1434 18.7175 10.4395 18.4214 10.4395 18.0512C10.4395 17.6811 10.1434 17.385 9.77329 17.385H5.54874C3.53023 17.2616 1.83251 15.3418 1.83251 13.1802C1.83251 11.7835 2.58266 10.4806 3.7918 9.77485C4.0731 9.61199 4.19155 9.27146 4.08297 8.96547C3.98427 8.69897 3.93492 8.41766 3.93492 8.11661C3.93492 6.75449 5.04534 5.64407 6.40746 5.64407C6.69864 5.64407 6.98489 5.69342 7.25139 5.79212C7.57711 5.91057 7.93738 5.76251 8.08544 5.45159C9.00833 3.49231 11.0022 2.22889 13.1687 2.22889C16.0805 2.22889 18.484 4.41026 18.7603 7.3023C18.7899 7.60335 19.017 7.84518 19.3131 7.89453C21.5093 8.26961 23.1675 10.298 23.1675 12.6126C23.1675 15.0654 21.2378 17.1974 18.859 17.38H15.2218C14.8516 17.38 14.5555 17.6761 14.5555 18.0463C14.5555 18.4164 14.8516 18.7125 15.2218 18.7125H18.8837C18.8985 18.7125 18.9133 18.7125 18.9331 18.7125C20.4383 18.604 21.8449 17.913 22.8911 16.7582C23.9325 15.6132 24.5 14.1425 24.5 12.6126C24.4951 9.84394 22.6049 7.38127 20.0188 6.7002Z"
                                      fill="#5B5D6B" />
                                    <path
                                      d="M16.4998 13.8172C16.7614 13.5556 16.7614 13.1361 16.4998 12.8745L12.9711 9.34585C12.8478 9.22247 12.675 9.14844 12.5023 9.14844C12.3296 9.14844 12.1568 9.21753 12.0335 9.34585L8.50477 12.8745C8.2432 13.1361 8.2432 13.5556 8.50477 13.8172C8.63308 13.9455 8.80582 14.0146 8.97362 14.0146C9.14141 14.0146 9.31415 13.9504 9.44246 13.8172L11.836 11.4236V22.4242C11.836 22.7943 12.1322 23.0904 12.5023 23.0904C12.8724 23.0904 13.1686 22.7943 13.1686 22.4242V11.4236L15.5621 13.8172C15.8188 14.0787 16.2383 14.0787 16.4998 13.8172Z"
                                      fill="#5B5D6B" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1346_15732">
                                      <rect width="24" height="24" fill="white"
                                        transform="translate(0.5)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Upload Your Photo
                            </>
                            : 
                              <img src={img.src} alt={img.alt} style={{height:"inherit",width:"auto",maxHeight:"100px"}} />
                            }
                            <input type="file" className="d-none" onChange={handleImage} accept='image/png,image/jpeg,image/jpg'/>
                          </label>
                        </div>
                        <span className='error'>{error.image_err}</span>
                      </div>

                      <div className="col-md-12">
                        <label for="inputBio" className="form-label" >Bio</label>
                        <input type="text" className="form-control" id="inputBio" name='bio' value={formData.bio} onChange={handleChange}/>
                        <span className='error'>{error.bio_err}</span>
                      </div>
                      {/* <div className="col-md-12">
                        <label for="inputBio" className="form-label"  >Socials <span className={error.atleast_one_social_err ? "error" : ""} > (Enter at least 1 social media handle) </span> </label>
                        <div className="login-social-link-list">
                          <div className="login-social-links">
                            <input type="text" className="form-control" id="inputBio" name='facebook' value={formData.facebook} onChange={handleChange}/>
                              <span className="login-social-links-icon">
                                <img src="assets/images/facebook-icon.png" alt="facebook-icon"
                                  className="img-fluid" />
                              </span>
                              <span className='error'>{error.facebook_err}</span>
                          </div>
                          <div className="login-social-links">
                            <input type="text" className="form-control" id="inputBio" name='twitter' value={formData.twitter} onChange={handleChange}/>
                              <span className="login-social-links-icon">
                                <img src="assets/images/twitter-icon.png" alt="twitter-icon"
                                  className="img-fluid" />
                              </span>
                              <span className='error'>{error.twitter_err}</span>
                          </div>
                          <div className="login-social-links">
                            <input type="text" className="form-control" id="inputBio" name='instagram' value={formData.instagram} onChange={handleChange}/>
                              <span className="login-social-links-icon">
                                <img src="assets/images/instagram-icon.png" alt="instagram-icon"
                                  className="img-fluid" />
                              </span>
                            <span className='error'>{error.instagram_err}</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12 col-lg-6">
                        <label for="inputPassword4" className="form-label" >Password</label>
                        <div className="om-input-group">
                          <input type={passwordType} className="form-control" id="inputPassword4" name='password' value={formData.password} onChange={handleChange}/>
                            <span className="om-input-icon" onClick={()=>{setPasswordType(passwordType==="password"?"text":"password")}}>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.2058 7.94718C13.9797 7.46366 13.6958 7.00372 13.3564 6.5781C12.0571 4.9489 10.0867 4 8.00288 4C5.91906 4 3.94864 4.9489 2.6494 6.5781C2.3099 7.00382 2.02588 7.4639 1.7998 7.94754"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path
                                  d="M14.2058 7.94702C13.9797 8.43068 13.6957 8.89074 13.3562 9.31646C12.057 10.9457 10.0865 11.8946 8.00272 11.8946C5.9189 11.8946 3.94848 10.9457 2.64924 9.31646C2.30982 8.89084 2.02584 8.4309 1.7998 7.94738"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path
                                  d="M7.06498 9.89543C7.65786 10.181 8.34848 10.181 8.94136 9.89543C9.53424 9.60991 9.96486 9.06995 10.1113 8.42839C10.2577 7.78685 10.104 7.11353 9.69374 6.59905C9.28346 6.08457 8.66122 5.78491 8.00316 5.78491C7.34512 5.78491 6.72288 6.08457 6.3126 6.59905C5.9023 7.11353 5.74862 7.78685 5.89506 8.42839C6.04148 9.06995 6.4721 9.60991 7.06498 9.89543Z"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path d="M3.20312 12.7999L12.8031 3.19995" stroke="#9496A1"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                            <span className='error'>{error.password_err}</span>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <label for="inputPassword4" className="form-label" >Confirm Password</label>
                        <div className="om-input-group">
                          <input type={cpasswordType} className="form-control" id="inputPassword4" name='cpassword' value={formData.cpassword} onChange={handleChange} />
                            <span className="om-input-icon" onClick={()=>{setCPasswordType(cpasswordType==="password"?"text":"password")}}>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.2058 7.94718C13.9797 7.46366 13.6958 7.00372 13.3564 6.5781C12.0571 4.9489 10.0867 4 8.00288 4C5.91906 4 3.94864 4.9489 2.6494 6.5781C2.3099 7.00382 2.02588 7.4639 1.7998 7.94754"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path
                                  d="M14.2058 7.94702C13.9797 8.43068 13.6957 8.89074 13.3562 9.31646C12.057 10.9457 10.0865 11.8946 8.00272 11.8946C5.9189 11.8946 3.94848 10.9457 2.64924 9.31646C2.30982 8.89084 2.02584 8.4309 1.7998 7.94738"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path
                                  d="M7.06498 9.89543C7.65786 10.181 8.34848 10.181 8.94136 9.89543C9.53424 9.60991 9.96486 9.06995 10.1113 8.42839C10.2577 7.78685 10.104 7.11353 9.69374 6.59905C9.28346 6.08457 8.66122 5.78491 8.00316 5.78491C7.34512 5.78491 6.72288 6.08457 6.3126 6.59905C5.9023 7.11353 5.74862 7.78685 5.89506 8.42839C6.04148 9.06995 6.4721 9.60991 7.06498 9.89543Z"
                                  stroke="#9496A1" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path d="M3.20312 12.7999L12.8031 3.19995" stroke="#9496A1"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                              <span className='error'>{error.cpassword_err}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type='submit' className="btn btn-dark login-btn w-100" disabled={disable} >Sign Up</button>
                      </div>
                      <div className="col-12">
                        <div className="text-center">
                          <p className="login-notes">Already Have an Account ? <Link to="/login">Sign
                            In</Link></p>
                        </div>
                      </div>
                    </form>
                  </div>
              {/* </div> */}
              </ScrollArea>
                </div>
            </div>
            <div className="col col-12 col-md-12 col-lg-6">
              <div className="rd-login-img">
                <img src="assets/images/login_img.png" alt="login-img" className="img-fluid w-100" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default SignUp