import React, { useState, useEffect } from 'react'
import { OM_API_URL } from './BASE_URL';
import axios from 'axios';

import parse from 'html-react-parser';

import { toast } from 'react-hot-toast';
import Welcome from './Welcome';
import Sidebar from './Sidebar';


const PrivacyPolicy = () => {


    useEffect(() => {
        getCmsList()
    }, [])

    const [about, setAbout] = useState('');
    const [loading, setLoading] = useState(true); 

    const getCmsList = () => {
        const myurl = `${OM_API_URL}/guru/get-cms`;

        axios({
            method: "post",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status) {
                    setAbout(response?.data?.data?.guide_cms?.privacy_policy);
                    setLoading(false);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
                setLoading(false)
            });
    };


    return (
        <div>
            <div class="rd-dashboard-content">
                <Sidebar />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Welcome />
                        <div class="rd-page-content" data-scrollbar style={{ fontFamily: "Source sans pro" }}>
                            {/* <div class="rd-notiofication-head" style={{ fontFamily: "Source sans pro" }}> */}
                               
                                    {loading ? (
                                        <h3 style={{ textAlign: "center" }}>Loading...</h3>
                                    ) : (
                                        parse(about) // Parse and render the 'about' HTML content
                                    )}
                            {/* </div> */}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default PrivacyPolicy