import React, { useState,useEffect } from 'react'
// import PhoneInput from 'react-phone-input-2'
import { Link, useNavigate,useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { OM_API_URL } from './BASE_URL'
import OTPInput from 'react-otp-input';

const Otp = () => {
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(59);
    const [seconds, setSeconds] = useState(59);
    const location = useLocation();
    const email = location.state.email;
    console.log(email, "0000000000")
    const [err, setErr] = useState('');
    const nav = useNavigate();
    const [disable,setDisable]=useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds,minutes]);
    
    const resendOTP = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('email', email);
        axios({
            method: 'post',
            url: `${OM_API_URL}/guru/otp-resend`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
        }).then((response) => {
            if (response.data.status === true) {
                setMinutes(59);
                setSeconds(59);
                setErr('');
            }
        }).catch((err) => {
            setErr(err.response.data.message)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp) {

            if (location.state.type !== 'forgot') {
                setDisable(true);
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('email', email);
                bodyFormData.append('otp', otp);
                axios({
                    method: 'post',
                    url: `${OM_API_URL}/guru/otp-verification`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    console.log(response.data);

                    if (response.data.status === true) {
                        toast.success(response.data.message+ " and your account is under approval");
                        nav('/login');
                        
                    }else{
                        toast.success(response.data.message);
                    } 
                    setDisable(false);
                }).catch((err) => {
                    toast.error(err.response.data.message)
                    setDisable(false);
                })
            } else {
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('email', email);
                bodyFormData.append('otp', otp);
                setDisable(true);
                axios({
                    method: 'post',
                    url: `${OM_API_URL}/guru/forget-password-otp-verification`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    // console.log(response.data);
                    if (response.data.status === true) {
                    
                        nav('/reset-password', { state: email });
                    }
                    setDisable(false);
                }).catch((err) => {
                    setErr(err.response.data.message)
                    setDisable(false);
                })
            }
        } else {
            setErr('Please enter otp')
        }
    }
    return (
        <div> <main class="rd-site-content">
            <section class="rd-login-section">
                <div class="row g-0">
                    <div class="col col-12 col-md-12 col-lg-6">
                        <div class="rd-login-content om-otp-verificarion-content">
                            <Link to="index.html" class="rd-login-logo">
                                <span class="rd-login-logo__img">
                                    <img src="assets/images/logo.svg" alt="logo" class="img-fluid om-logo" />
                                </span>
                            </Link>
                            <div class="rd-login-form-content" data-scrollbar>
                                <div class="rd-login-form-heading">
                                    <h1 class="rd-login-form-title">OTP Verification</h1>
                                    <p class="rd-login-form-description">Enter the OTP you received via register email
                                        id for further process</p>
                                </div>
                                <div class="rd-login-form">
                                    <form class="row" autocomplete="off" action="" onSubmit={handleSubmit}  >
                                        <div class="col-md-12">
                                            <label for="inputUsername" class="form-label">OTP</label>
                                                <OTPInput
                                                    value={otp}
                                                    onChange={(val) => setOtp(val)}
                                                    numInputs={6}
                                                    inputStyle={{ width: '4rem', height: '4rem', marginRight: '15px' }}
                                                    renderInput={(props) => <input {...props} />} 
                                                // separator={<span>       </span>}
                                                />
                                                <span className='error'>{err}</span>

                                                {/* <input type="" className="form-control" id="inputUsername" /> */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p style={{ paddingTop: '30px' }}>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <>
                                                        <p style={{ paddingTop: '30px' }}>Didn't recieve code?</p>
                                                        <div class="text-end">
                                                            <Link to="#" class="rd-forgot-passlink" onClick={resendOTP}>Resend OTP</Link>
                                                        </div>
                                                        </>
                                                    )}
                                                    
                                                </div>
                                                
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-dark login-btn w-100" disabled={disable}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <p class="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
                        </div>
                    </div>
                    <div class="col col-12 col-md-12 col-lg-6">
                        <div class="rd-login-img">
                            <img src="assets/images/login_img.png" alt="login-img" class="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </div>
    )
}

export default Otp