import React from 'react'

const EnterEmail = () => {
    return (
        <div>
            <main class="rd-site-content">
                <section class="rd-login-section">
                    <div class="row g-0">
                        <div class="col col-12 col-md-12 col-lg-6">
                            <div class="rd-login-content om-otp-verificarion-content">
                                <a href="index.html" class="rd-login-logo">
                                    <span class="rd-login-logo__img">
                                        <img src="assets/images/logo.png" alt="logo" class="img-fluid" />
                                    </span>
                                </a>
                                <div class="rd-login-form-content" data-scrollbar>
                                    <div class="rd-login-form-heading">
                                        <h1 class="rd-login-form-title">Enter your mail ID</h1>
                                        <p class="rd-login-form-description">Enter your email so we can send you a secure code
                                            for you to set a new password.It wil only take a minute.</p>
                                    </div>
                                    <div class="rd-login-form">
                                        <form class="row" autocomplete="off" action="sign-up-2.html">
                                            <div class="col-md-12">
                                                <label for="inputEmail" class="form-label">Email</label>
                                                <input type="text" class="form-control" id="inputEmail" />
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-dark login-btn w-100">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p class="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
                            </div>
                        </div>
                        <div class="col col-12 col-md-12 col-lg-6">
                            <div class="rd-login-img">
                                <img src="assets/images/login_img.png" alt="login-img" class="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default EnterEmail