import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Welcome from './Welcome'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { OM_API_URL } from './BASE_URL'
import { toast } from 'react-hot-toast'

const Notification = () => {
    const nav = useNavigate();
    const [Loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState({});
    useEffect(() => {
        getUpcomingEvents()
        // eslint-disable-next-line
    }, []);
    
    const getUpcomingEvents = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/guru/get-notifications`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
        }).then((response) => {
            console.log(response?.data, "##$$")
            if (response.data.status) {
                setNotifications(response.data.data);
                console.log(notifications)
            }
            else {
                toast.error(response.data.message)
            }
            setLoading(false)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
            if (err.response.status === 401) {
                localStorage.removeItem('OM_GURU_TOKEN');
                localStorage.removeItem('OM_GURU_NAME');
                localStorage.removeItem('OM_GURU_EMAIL');
                localStorage.removeItem('OM_GURU_IMAGE');
                nav('/login');
            }
            // setError({ ...error, password_err: err.response.data.message })
        })
    }
    return (
        <div>
            <div class="rd-dashboard-content">
                <Sidebar />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Welcome />
                        <div class="rd-page-content" data-scrollbar>
                            <div class="rd-notiofication-content">
                                <div class="rd-notiofication-head">
                                    <h3 class="page-title">Notifications</h3>
                                    {/* <Link href="#" class="mark-read-link">Mark as Read</Link> */}
                                </div>

                                {Loading ? <h3 style={{ textAlign: "center" }}>Loading</h3> :
                                    <ul class="rd-notiofication-list">
                                        {Object.keys(notifications).map((date) => {
                                            return (
                                                <>
                                                    <h2 className='page-title' style={{ fontSize: "14px" }}>{date}</h2>
                                                    {notifications[date].map((notification) => {
                                                        return (

                                                            <li class="rd-notiofication-item">
                                                                <span class="rd-notiofication-link">
                                                                    <div class="rd-notiofication-img">
                                                                        <img src={notification.notification_image} alt="Notification" class="img-fluid" style={{ width: "40px" }} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = '/assets/images/noti-img.png' }} />
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="20" height="20" rx="10" fill="#F7F8FA" />
                                                                            <g clip-path="url(#clip0_1352_19048)">
                                                                                <path
                                                                                    d="M9.99996 2.66675C5.9562 2.66675 2.66663 5.95632 2.66663 10.0001C2.66663 14.0438 5.9562 17.3334 9.99996 17.3334C14.0437 17.3334 17.3333 14.0438 17.3333 10.0001C17.3333 5.95632 14.0437 2.66675 9.99996 2.66675ZM13.7168 8.44604L9.7445 12.4182C9.62533 12.5374 9.46889 12.5973 9.31246 12.5973C9.15603 12.5973 8.99959 12.5374 8.88042 12.4182L6.89435 10.4321C6.65533 10.1932 6.65533 9.80695 6.89435 9.56804C7.13325 9.32903 7.51941 9.32903 7.75842 9.56804L9.31246 11.1221L12.8527 7.58197C13.0916 7.34296 13.4777 7.34296 13.7168 7.58197C13.9557 7.82087 13.9557 8.20703 13.7168 8.44604Z"
                                                                                    fill="#00A040" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1352_19048">
                                                                                    <rect width="16" height="16" fill="white"
                                                                                        transform="translate(2 2)" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </div>
                                                                    <p class="rd-notiofication-text">{notification.notification_body} </p>
                                                                    <div class="rd-notiofication-time">
                                                                        <p class="rd-notiofication-time__inner">
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M8.00001 15.1666C4.04668 15.1666 0.833344 11.9533 0.833344 7.99992C0.833344 4.04659 4.04668 0.833252 8.00001 0.833252C11.9533 0.833252 15.1667 4.04659 15.1667 7.99992C15.1667 11.9533 11.9533 15.1666 8.00001 15.1666ZM8.00001 1.83325C4.60001 1.83325 1.83334 4.59992 1.83334 7.99992C1.83334 11.3999 4.60001 14.1666 8.00001 14.1666C11.4 14.1666 14.1667 11.3999 14.1667 7.99992C14.1667 4.59992 11.4 1.83325 8.00001 1.83325Z"
                                                                                    fill="#292D32" />
                                                                                <path
                                                                                    d="M10.4733 10.6199C10.3867 10.6199 10.3 10.5999 10.22 10.5466L8.15334 9.31326C7.64001 9.00659 7.26001 8.33326 7.26001 7.73992V5.00659C7.26001 4.73326 7.48668 4.50659 7.76001 4.50659C8.03334 4.50659 8.26001 4.73326 8.26001 5.00659V7.73992C8.26001 7.97992 8.46001 8.33326 8.66668 8.45326L10.7333 9.68659C10.9733 9.82659 11.0467 10.1333 10.9067 10.3733C10.8067 10.5333 10.64 10.6199 10.4733 10.6199Z"
                                                                                    fill="#292D32" />
                                                                            </svg>
                                                                            <span>{notification.createdAt.substring(0, notification.createdAt.lastIndexOf(" "))}</span>
                                                                        </p>
                                                                        <p class="rd-notiofication-time__inner">
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M5.33333 3.83325C5.05999 3.83325 4.83333 3.60659 4.83333 3.33325V1.33325C4.83333 1.05992 5.05999 0.833252 5.33333 0.833252C5.60666 0.833252 5.83333 1.05992 5.83333 1.33325V3.33325C5.83333 3.60659 5.60666 3.83325 5.33333 3.83325Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M10.6667 3.83325C10.3933 3.83325 10.1667 3.60659 10.1667 3.33325V1.33325C10.1667 1.05992 10.3933 0.833252 10.6667 0.833252C10.94 0.833252 11.1667 1.05992 11.1667 1.33325V3.33325C11.1667 3.60659 10.94 3.83325 10.6667 3.83325Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M5.66667 9.66655C5.58 9.66655 5.49333 9.64656 5.41333 9.61322C5.32667 9.57989 5.26 9.53321 5.19333 9.47321C5.07333 9.34654 5 9.17988 5 8.99988C5 8.91322 5.02 8.82655 5.05333 8.74655C5.08667 8.66655 5.13333 8.59322 5.19333 8.52655C5.26 8.46655 5.32667 8.41987 5.41333 8.38654C5.65333 8.28654 5.95333 8.33989 6.14 8.52655C6.26 8.65322 6.33333 8.82655 6.33333 8.99988C6.33333 9.03988 6.32667 9.08656 6.32 9.13322C6.31333 9.17322 6.3 9.21322 6.28 9.25322C6.26667 9.29322 6.24667 9.33321 6.22 9.37321C6.2 9.40655 6.16667 9.43988 6.14 9.47321C6.01333 9.59321 5.84 9.66655 5.66667 9.66655Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M7.99999 9.66658C7.91333 9.66658 7.82666 9.64659 7.74666 9.61326C7.65999 9.57992 7.59333 9.53324 7.52666 9.47324C7.40666 9.34658 7.33333 9.17992 7.33333 8.99992C7.33333 8.91325 7.35333 8.82658 7.38666 8.74658C7.42 8.66658 7.46666 8.59325 7.52666 8.52659C7.59333 8.46659 7.65999 8.41991 7.74666 8.38657C7.98666 8.27991 8.28666 8.33992 8.47333 8.52659C8.59333 8.65325 8.66666 8.82658 8.66666 8.99992C8.66666 9.03992 8.65999 9.08659 8.65333 9.13326C8.64666 9.17326 8.63333 9.21325 8.61333 9.25325C8.59999 9.29325 8.58 9.33325 8.55333 9.37325C8.53333 9.40658 8.49999 9.43991 8.47333 9.47324C8.34666 9.59324 8.17333 9.66658 7.99999 9.66658Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M10.3333 9.66658C10.2467 9.66658 10.16 9.64659 10.08 9.61326C9.99334 9.57992 9.92667 9.53324 9.86001 9.47324C9.83334 9.43991 9.80667 9.40658 9.78 9.37325C9.75334 9.33325 9.73334 9.29325 9.72001 9.25325C9.70001 9.21325 9.68667 9.17326 9.68001 9.13326C9.67334 9.08659 9.66667 9.03992 9.66667 8.99992C9.66667 8.82658 9.74001 8.65325 9.86001 8.52659C9.92667 8.46659 9.99334 8.41991 10.08 8.38657C10.3267 8.27991 10.62 8.33992 10.8067 8.52659C10.9267 8.65325 11 8.82658 11 8.99992C11 9.03992 10.9933 9.08659 10.9867 9.13326C10.98 9.17326 10.9667 9.21325 10.9467 9.25325C10.9333 9.29325 10.9133 9.33325 10.8867 9.37325C10.8667 9.40658 10.8333 9.43991 10.8067 9.47324C10.68 9.59324 10.5067 9.66658 10.3333 9.66658Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M5.66667 12C5.58 12 5.49333 11.9801 5.41333 11.9467C5.33333 11.9134 5.26 11.8667 5.19333 11.8067C5.07333 11.68 5 11.5067 5 11.3334C5 11.2467 5.02 11.16 5.05333 11.08C5.08667 10.9934 5.13333 10.92 5.19333 10.86C5.44 10.6134 5.89333 10.6134 6.14 10.86C6.26 10.9867 6.33333 11.16 6.33333 11.3334C6.33333 11.5067 6.26 11.68 6.14 11.8067C6.01333 11.9267 5.84 12 5.66667 12Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M7.99999 12C7.82666 12 7.65333 11.9267 7.52666 11.8067C7.40666 11.68 7.33333 11.5067 7.33333 11.3334C7.33333 11.2467 7.35333 11.16 7.38666 11.08C7.42 10.9934 7.46666 10.92 7.52666 10.86C7.77333 10.6134 8.22666 10.6134 8.47333 10.86C8.53333 10.92 8.57999 10.9934 8.61333 11.08C8.64666 11.16 8.66666 11.2467 8.66666 11.3334C8.66666 11.5067 8.59333 11.68 8.47333 11.8067C8.34666 11.9267 8.17333 12 7.99999 12Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M10.3333 11.9999C10.16 11.9999 9.98667 11.9266 9.86001 11.8066C9.80001 11.7466 9.75334 11.6733 9.72001 11.5866C9.68667 11.5066 9.66667 11.4199 9.66667 11.3333C9.66667 11.2466 9.68667 11.1599 9.72001 11.0799C9.75334 10.9933 9.80001 10.9199 9.86001 10.8599C10.0133 10.7066 10.2467 10.6333 10.46 10.6799C10.5067 10.6866 10.5467 10.6999 10.5867 10.7199C10.6267 10.7333 10.6667 10.7533 10.7067 10.78C10.74 10.8 10.7733 10.8333 10.8067 10.8599C10.9267 10.9866 11 11.1599 11 11.3333C11 11.5066 10.9267 11.6799 10.8067 11.8066C10.68 11.9266 10.5067 11.9999 10.3333 11.9999Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M13.6667 6.56006H2.33333C2.05999 6.56006 1.83333 6.33339 1.83333 6.06006C1.83333 5.78673 2.05999 5.56006 2.33333 5.56006H13.6667C13.94 5.56006 14.1667 5.78673 14.1667 6.06006C14.1667 6.33339 13.94 6.56006 13.6667 6.56006Z"
                                                                                    fill="#212121" />
                                                                                <path
                                                                                    d="M10.6667 15.1666H5.33333C2.9 15.1666 1.5 13.7666 1.5 11.3333V5.66659C1.5 3.23325 2.9 1.83325 5.33333 1.83325H10.6667C13.1 1.83325 14.5 3.23325 14.5 5.66659V11.3333C14.5 13.7666 13.1 15.1666 10.6667 15.1666ZM5.33333 2.83325C3.42667 2.83325 2.5 3.75992 2.5 5.66659V11.3333C2.5 13.2399 3.42667 14.1666 5.33333 14.1666H10.6667C12.5733 14.1666 13.5 13.2399 13.5 11.3333V5.66659C13.5 3.75992 12.5733 2.83325 10.6667 2.83325H5.33333Z"
                                                                                    fill="#212121" />
                                                                            </svg>
                                                                            <span>{notification.createdAt.substring(notification.createdAt.lastIndexOf(" "))}</span>
                                                                        </p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}

                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Notification