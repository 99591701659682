import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar';
import Welcome from './Welcome';

import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { OM_API_URL } from './BASE_URL';
import { toast } from 'react-hot-toast';

const UserLike = () => {

    const location = useLocation();
    const nav = useNavigate();

    const [user, setUser] = useState([])
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        getUserLikes()
        // eslint-disable-next-line
    }, []);

    console.log(location.state.event)

    const getUserLikes = () => {

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('event_id', location?.state?.event);

        axios({
            method: 'post',
            data: bodyFormData,
            url: `${OM_API_URL}/guru/get-likes-event`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
        }).then((response) => {
            console.log(response.data, "$$")
            if (response.data.status === true) {
                setUser(response?.data?.data)
                console.log(user, "user")
            }
            else {
                toast.error(response.data.message)
            }
            setLoading(false)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
            if (err.response.status === 401) {
                localStorage.removeItem('OM_GURU_TOKEN');
                localStorage.removeItem('OM_GURU_NAME');
                localStorage.removeItem('OM_GURU_EMAIL');
                localStorage.removeItem('OM_GURU_IMAGE');
                nav('/login');
            }
        })
    }
    return (
        <div>
            <div className="rd-dashboard-content">
                <Sidebar />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Welcome />
                        <div class="rd-page-content" data-scrollbar>
                            <div class="rd-notiofication-head">
                                <h3 class="page-title">User Likes</h3>
                            </div>
                            <div class="rd-content-area">
                                <div class="rd-customers-table">
                                    <div class="table-responsive" data-scrollbar>
                                        <table class="table table-borderless table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {Loading ? <tr><td colSpan={6}><h3>Loading</h3></td></tr> :
                                                    user.map((likes) => {
                                                        return (
                                                            <tr className='mb-2'>
                                                                <td>
                                                                    <span class="donation-img">
                                                                        <img src={likes.image} alt="donation-img" style={{ objectFit: "cover", borderRadius: "9px" }}
                                                                            class="img-fluid" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = '/assets/images/event-img.png' }} />
                                                                    </span>
                                                                </td>
                                                                <td>{likes.user || "-"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                } */}
                                                {
                                                    // Check if the user data is still loading
                                                    Loading ? (
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <h3>Loading...</h3>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        // Check if there is no data available
                                                        user.length === 0 ? (
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <h3>No data found</h3>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            // Render the user data if available
                                                            user.map((likes) => {
                                                                return (
                                                                    <tr className='mb-2'>
                                                                        <td>
                                                                            <span class="donation-img">
                                                                                <img
                                                                                    src={likes.image}
                                                                                    alt="donation-img"
                                                                                    style={{ objectFit: "cover", borderRadius: "9px" }}
                                                                                    class="img-fluid"
                                                                                    onError={({ currentTarget }) => {
                                                                                        currentTarget.onerror = null;
                                                                                        currentTarget.src = "/assets/images/event-img.png";
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                        <td>{likes.user || "-"}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )
                                                    )
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default UserLike