import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { OM_API_URL } from './BASE_URL';
import { toast } from 'react-hot-toast';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const [disable, setDisable] = useState(false);
    const nav = useNavigate();
   
    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            setDisable(true)
            axios({
                method: 'post',
                url: `${OM_API_URL}/guru/otp-resend`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log(response.data);
                if (response.data.status === true) {
                    toast.success(response.data.message);
                    nav('/otp-verification', { state: { email: email, type: 'forgot' } })
                }else{
                    toast.error(response.data.message);
                }
                setDisable(false)
            }).catch((err) => {
                toast.error(err.response.data.message);
                setDisable(false)
            })
        } else {
            setErr('Please enter email')
        }
    }

    return (
        <div> <main class="rd-site-content">
            <section class="rd-login-section">
                <div class="row g-0">
                    <div class="col col-12 col-md-12 col-lg-6">
                        <div class="rd-login-content om-otp-verificarion-content">
                            <a href="index.html" class="rd-login-logo">
                                <span class="rd-login-logo__img">
                                    <img src="assets/images/logo.svg" alt="logo" class="img-fluid om-logo" />
                                </span>
                            </a>
                            <div class="rd-login-form-content" data-scrollbar>
                                <div class="rd-login-form-heading">
                                    <h1 class="rd-login-form-title">Forgot Password</h1>
                                </div>
                                <div class="rd-login-form">
                                    <form class="row" autocomplete="off" >
                                        <div class="col-md-12">
                                            <label for="inputUsername" class="form-label">Email Id</label>
                                            <input type="text" className={err ? "form-control error" : "form-control"} id="inputEmail" onChange={(e) => setEmail(e.target.value)} />
                                            <span for="inputEmail" className="error">{err}</span>
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" onClick={handleSubmit} class="btn btn-dark login-btn w-100" disabled={disable}>Send Otp</button>
                                        </div>
                                        <div className="col-12">
                                            <div className="text-center">
                                            <p className="login-notes">Back to <Link to="/login">Sign
                                                In</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <p class="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
                        </div>
                    </div>
                    <div class="col col-12 col-md-12 col-lg-6">
                        <div class="rd-login-img">
                            <img src="assets/images/login_img.png" alt="login-img" class="img-fluid w-100" />
                        </div>
                    </div>

                    
                      
                </div>
            </section>
        </main>
        </div>
    )
}

export default ForgotPassword