import React, { useState } from 'react'
import { toast } from 'react-hot-toast';

import { Modal } from 'react-bootstrap';
import { OM_API_URL } from './BASE_URL';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

const Sidebar = () => {
    const nav = useNavigate();
    const logout = () => {
        localStorage.removeItem('OM_GURU_TOKEN')
        localStorage.removeItem('OM_GURU_IMAGE')
        localStorage.removeItem('OM_GURU_EMAIL')
        toast.success("Logout Successfully")
        nav('/login')
    }

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        console.log("open");
        setShow(true);
    }

    const handleDelete = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/guru/delete-account`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
        }).then((response) => {
            console.log(response?.data , "delete account");
            toast.success(response?.data?.message);
            nav('/login')
        }).then((error) => {
            console.log(error, "error")
        })
    }

    var eventClass =
        (window.location.pathname.match(/^\/upcoming-events/) ||
            window.location.pathname.match(/^\/past-events/))
            ? "nav-link active"
            : "nav-link";

    // var donationClass =
    //     window.location.pathname.match(/^\/donation-history/)
    //         ? "nav-link active"
    //         : "nav-link";

    var likeClass =
        window.location.pathname.match(/^\/like-history/)
            ? "nav-link active"
            : "nav-link";

    var aboutClass =
        window.location.pathname.match(/^\/about-us/)
            ? "nav-link active"
            : "nav-link";

    var termsClass =
        window.location.pathname.match(/^\/terms-and-conditions/)
            ? "nav-link active"
            : "nav-link";

    var privacyClass =
        window.location.pathname.match(/^\/privacy-policy/)
            ? "nav-link active"
            : "nav-link";


    var contactClass =
        window.location.pathname.match(/^\/contact-us/)
            ? "nav-link active"
            : "nav-link";



    return (
        <div>
            <header className="rd-site-header">
                <nav className="navbar">
                    <Link className="navbar-brand" to="#">
                        <img src="/assets/images/logo.svg" alt="Logo" className="img-fluid om-logo" />
                    </Link>
                    <div className="collapse navbar-collapse show" id="navbarNav" data-scrollbar>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className={eventClass} to="/upcoming-events">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.9998 9.99927L12.6284 11.3192L14.0426 11.5287L13.016 12.5554L13.2569 14.001L11.9998 13.3201L10.7427 14.001L10.9837 12.5554L9.95703 11.5287L11.3713 11.3192L11.9998 9.99927Z"
                                            stroke="#7C7C7C" stroke-width="2.00087" stroke-miterlimit="10" />
                                        <path d="M7.99805 18.9978H16.0015" stroke="#7C7C7C" stroke-width="2.00087"
                                            stroke-miterlimit="10" />
                                        <path d="M20.9986 1.00049H3.00122V22.9996H20.9986V1.00049Z" stroke="#7C7C7C"
                                            stroke-width="2.00087" stroke-miterlimit="10" />
                                        <path
                                            d="M7.99805 5.9974C9.2388 5.36534 10.6075 5.02495 11.9998 5.0022C13.3921 5.02495 14.7608 5.36534 16.0015 5.9974"
                                            stroke="#7C7C7C" stroke-width="2.00087" stroke-miterlimit="10" />
                                    </svg>

                                    <span>My Events</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="order-summary-checkouut.html">
                                    <svg width="25" height="16" viewBox="0 0 25 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect width="25" height="16" fill="#7C7C7C" />
                                        <path
                                            d="M5.72 11V5.752H6.384V10.432H8.68V11H5.72ZM9.61063 11V5.752H10.2746V11H9.61063ZM12.6722 11L10.9922 5.752H11.7042L12.5442 8.584C12.6402 8.89333 12.7229 9.184 12.7922 9.456C12.8669 9.72267 12.9549 10.0107 13.0562 10.32H13.0882C13.1842 10.0107 13.2695 9.72267 13.3442 9.456C13.4189 9.184 13.5015 8.89333 13.5922 8.584L14.4322 5.752H15.1122L13.4482 11H12.6722ZM15.8294 11V5.752H18.8534V6.312H16.4934V7.96H18.4854V8.528H16.4934V10.432H18.9334V11H15.8294Z"
                                            fill="white" />
                                    </svg>
                                    <span>Live Session</span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className={donationClass} to="/donation-history">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1349_18504)">
                                            <path
                                                d="M10.09 16.77H12.48C12.8593 16.77 13.223 16.6194 13.4912 16.3512C13.7594 16.083 13.91 15.7193 13.91 15.34C13.91 14.9608 13.7594 14.5971 13.4912 14.3289C13.223 14.0607 12.8593 13.91 12.48 13.91H11.48C11.1008 13.91 10.7371 13.7594 10.4689 13.4912C10.2007 13.223 10.05 12.8593 10.05 12.48C10.05 12.1008 10.2007 11.7371 10.4689 11.4689C10.7371 11.2007 11.1008 11.05 11.48 11.05H13.87"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10"
                                                stroke-linecap="square" />
                                            <path d="M12 9.13989V11.0499" stroke="black" stroke-width="1.5"
                                                stroke-miterlimit="10" stroke-linecap="square" />
                                            <path d="M12 16.77V18.68" stroke="black" stroke-width="1.5"
                                                stroke-miterlimit="10" stroke-linecap="square" />
                                            <path d="M22.5 1.5V11.04H17.73V6.27H6.27V11.04H1.5V1.5H22.5Z" stroke="black"
                                                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
                                            <path d="M6.27002 6.27002H17.73" stroke="black" stroke-width="1.5"
                                                stroke-miterlimit="10" stroke-linecap="square" />
                                            <path d="M17.72 6.27002H6.27002V22.5H17.72V6.27002Z" stroke="black"
                                                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
                                            <path d="M4.36011 6.27002H19.6401" stroke="black" stroke-width="1.5"
                                                stroke-miterlimit="10" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1349_18504">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>My Earnings</span>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className={likeClass} to="/like-history">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z" fill="#1C274C" />
                                    </svg>
                                    <span>Like Lists</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={aboutClass} to="/about-us">
                                    {/* <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m 8 0 c -4.410156 0 -8 3.589844 -8 8 s 3.589844 8 8 8 s 8 -3.589844 8 -8 s -3.589844 -8 -8 -8 z m 0 2 c 3.332031 0 6 2.667969 6 6 s -2.667969 6 -6 6 s -6 -2.667969 -6 -6 s 2.667969 -6 6 -6 z m 0 1.875 c -0.621094 0 -1.125 0.503906 -1.125 1.125 s 0.503906 1.125 1.125 1.125 s 1.125 -0.503906 1.125 -1.125 s -0.503906 -1.125 -1.125 -1.125 z m -1.523438 3.125 c -0.265624 0.011719 -0.476562 0.230469 -0.476562 0.5 c 0 0.277344 0.222656 0.5 0.5 0.5 h 0.5 v 3 h -0.5 c -0.277344 0 -0.5 0.222656 -0.5 0.5 s 0.222656 0.5 0.5 0.5 h 3 c 0.277344 0 0.5 -0.222656 0.5 -0.5 s -0.222656 -0.5 -0.5 -0.5 h -0.5 v -4 h -2.5 c -0.007812 0 -0.015625 0 -0.023438 0 z m 0 0" fill="#2e3436" />
                                    </svg> */}
                                    <svg fill="#000000" width="800px" height="800px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">

                                        <title />

                                        <g>

                                            <path d="M66,84H54V42a5.9966,5.9966,0,0,0-6-6H36a6,6,0,0,0,0,12h6V84H30a6,6,0,0,0,0,12H66a6,6,0,0,0,0-12Z" />

                                            <path d="M48,24A12,12,0,1,0,36,12,12.0119,12.0119,0,0,0,48,24Z" />

                                        </g>

                                    </svg>
                                    {/* <svg width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M789.333333 853.333333H234.666667l-128 128V256c0-70.4 57.6-128 128-128h554.666666c70.4 0 128 57.6 128 128v469.333333c0 70.4-57.6 128-128 128z" fill="#2196F3" /><path d="M469.333333 426.666667h85.333334v234.666666h-85.333334z" fill="#FFFFFF" /><path d="M512 320m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z" fill="#FFFFFF" /></svg> */}
                                    <span>About Us</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={termsClass} to="/terms-and-conditions">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2L8.93417 2C8.04768 1.99995 7.28387 1.99991 6.67221 2.08215C6.01669 2.17028 5.38834 2.36902 4.87868 2.87868C4.36902 3.38835 4.17027 4.0167 4.08214 4.67221C3.9999 5.28387 3.99995 6.04769 4 6.93417L4 7V10.5V16.1707C2.83481 16.5825 2 17.6938 2 19C2 20.6569 3.34315 22 5 22H15.9966L16 22C17.6569 22 19 20.6569 19 19V9.00001V7.00001H19.5C20.8807 7.00001 22 5.88072 22 4.50001C22 3.11929 20.8807 2.00001 19.5 2.00001C19.3961 2.00001 19.2937 2.00634 19.1932 2.01865C19.1307 2.00641 19.0661 2 19 2H9ZM13.1707 20C13.0602 19.6872 13 19.3506 13 19V18H5C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H13.1707ZM19 5.00001H19.5C19.7761 5.00001 20 4.77615 20 4.50001C20 4.22386 19.7761 4.00001 19.5 4.00001C19.2239 4.00001 19 4.22386 19 4.50001V5.00001ZM8 7C8 6.44772 8.44772 6 9 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H9C8.44772 8 8 7.55228 8 7ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H14C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10H9Z" fill="#000000" />
                                    </svg>
                                    <span>Terms & Conditions</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={privacyClass} to="/privacy-policy">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="9" r="2" stroke="#1C274C" stroke-width="1.5" />
                                        <path d="M16 15C16 16.1046 16 17 12 17C8 17 8 16.1046 8 15C8 13.8954 9.79086 13 12 13C14.2091 13 16 13.8954 16 15Z" stroke="#1C274C" stroke-width="1.5" />
                                        <path d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 14.4963 20.1632 16.4284 19 17.9041M3.19284 14C4.05026 18.2984 7.57641 20.5129 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22C13.0193 22 13.38 21.8424 14.1014 21.5273C14.6796 21.2747 15.3324 20.9478 16 20.5328" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <span>Privacy policy</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={contactClass} to="/contact-us">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 18L10.29 20.29C10.514 20.5156 10.7804 20.6946 11.0739 20.8168C11.3674 20.9389 11.6821 21.0018 12 21.0018C12.3179 21.0018 12.6326 20.9389 12.9261 20.8168C13.2196 20.6946 13.486 20.5156 13.71 20.29L16 18H18C19.0609 18 20.0783 17.5786 20.8284 16.8285C21.5786 16.0783 22 15.0609 22 14V7C22 5.93913 21.5786 4.92178 20.8284 4.17163C20.0783 3.42149 19.0609 3 18 3H6C4.93913 3 3.92172 3.42149 3.17157 4.17163C2.42142 4.92178 2 5.93913 2 7V14C2 15.0609 2.42142 16.0783 3.17157 16.8285C3.92172 17.5786 4.93913 18 6 18H8Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M17 9H7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 12H7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span>Contact Us</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className='nav-link'  onClick={handleOpen}>
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span>Delete Account</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="rd-logout-link" onClick={logout}>
                        <Link className="rd-logout-link__inner" >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_162_1685" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0"
                                    width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_162_1685)">
                                    <path
                                        d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H12V5H5V19H12V21H5ZM16 17L14.625 15.55L17.175 13H9V11H17.175L14.625 8.45L16 7L21 12L16 17Z"
                                        fill="white" />
                                </g>
                            </svg>
                            <span>Logout</span>
                        </Link>
                    </div>
                </nav>
            </header>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='contact-us-modal' style={{ padding: 0 }}>
                    <div>
                        <img src='assets/images/guru-contact.png' alt="" className="img-fluid conatct-us-img" />
                        <div style={{ textAlign: "center" }}>
                            <p className='contact-text'>Are you sure you want to delete this account?</p>
                            <div className='row'>
                                <div className='col mb-4' >
                                    <button className='btn btn-danger mx-2' style={{ borderRadius: "9px" }} onClick={handleDelete}>Delete</button>
                                    <button className='btn btn-secondary mx-2' style={{ borderRadius: "9px" }} onClick={handleClose}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Sidebar