import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import { OM_API_URL } from './BASE_URL';
import { toast } from 'react-hot-toast';
const Login = () => {
  const [passwordType,setPasswordType] = useState("password");
  const [details, setDetails] = useState({ email: "", password: "", pwdShow: true });
  const [error, setError] = useState({});
  const [Loading, setLoading] = useState(false);
  const nav = useNavigate();

  useEffect(()=>{

    if(localStorage.getItem('OM_GURU_TOKEN')){
      toast.success("Already Logged-In")
        nav('/upcoming-events')
    }
    // eslint-disable-next-line
  },[])


  const handleChange = (e) => {
      setDetails({ ...details, [e.target.name]: e.target.value })
  }

  const validation = () => {
      var isValid = true;
      let err = {};

      if (!details.email) {
          isValid = false;
          err['email_err'] = "Please enter your email address"
      }
      else if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(details.email))){
          isValid = false;
          err['email_err'] = "Please enter valid email address"
      }
      else{
        err['email_err']=""
      }

      if (!details.password) {
          isValid = false;
          err['password_err'] = "Please enter your password"
      }
      else{
        err['password_err']=""
      }
      setError(err);
      return isValid;
  }

  const handleSubmit =  (e) => {
      e.preventDefault();
      if (validation()) {
          setLoading(true);
          const bodyFormData = new URLSearchParams();
          bodyFormData.append('email', details.email);
          bodyFormData.append('password', details.password)
          bodyFormData.append('fcm_token',"-")
          axios({
              method: 'post',
              url: `${OM_API_URL}/guru/sign-in`,
              data: bodyFormData,
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then((response) => {
              console.log(response.data);
              if(response.data.status === false){
                setLoading(false);
                toast(response.data.message,{icon: '⌛'});
              }
              else if (response.data.verified === false) {
                
                setLoading(false);
                toast.error(response.data.message);
                nav('/otp-verification', { state: { email: details.email, type: 'login' } })

              } else {      
                  const getDatabodyFormData = new URLSearchParams();
                  localStorage.setItem('OM_GURU_TOKEN',response.data.data[0].token);
                  axios({
                      method: 'post',
                      url: `${OM_API_URL}/guru/get-guru-data`,
                      data: getDatabodyFormData,
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded','Authorization':`Bearer ${response.data.data[0].token}` }
                  }).then((response) => {
                      setLoading(false);

                      console.log(response.data);
                      if (response.data.status === true) {
                          
                          localStorage.setItem('OM_GURU_NAME',response.data.data.name);
                          localStorage.setItem('OM_GURU_EMAIL',response.data.data.email);
                          localStorage.setItem('OM_GURU_IMAGE',response.data.data.image);
                          nav('/upcoming-events');
                      } else {      
                          toast.error(response.data.message);
                          // localStorage.clear();
                          localStorage.removeItem('OM_GURU_NAME');
                          localStorage.removeItem('OM_GURU_TOKEN');
                          localStorage.removeItem('OM_GURU_EMAIL');
                          localStorage.removeItem('OM_GURU_IMAGE');
                          nav('/login');
                      }
                  }).catch((err) => {
                    setLoading(false);
                    console.log(err)
                      toast.error(err.response.data.message);
                      nav('/login');

                     
                  })
                  
            
                  
              }
          }).catch((err) => {
              toast.error(err.response.data.message);
              setLoading(false);

              // setError({ ...error, password_err: err.response.data.message })
          })
         
         


      }
  }
  return (
    <>
      <main className="rd-site-content">
        {/* <Toaster position="top-left"/> */}
        <section className="rd-login-section">
          <div className="row g-0">
            <div className="col col-12 col-md-12 col-lg-6">
              <div className="rd-login-content">
                <Link to="/" className="rd-login-logo">
                  <span className="rd-login-logo__img">
                    <img src="assets/images/logo.svg" alt="logo" className="img-fluid om-logo" />
                  </span>
                </Link>
                <ScrollArea speed={2}
                      className="rd-login-form-content"
                      contentClassName="content"
                      horizontal={false}
                      smoothScrolling={true}
                  >
                {/* <div className=" data-scrollbar> */}
                  <div className="rd-login-form-heading">
                    <h1 className="rd-login-form-title">Guide Login</h1>
                    <p className="rd-login-form-description">Sign into your account</p>
                  </div>
                  <div className="rd-login-form">
                    <form className="row g-0" autoComplete="off" action="dashboard.html">
                      <div className="col-md-12">
                        <label htmlFor="inputEmail" className="form-label">Email</label>
                        <input type="email" className={error.email_err ? "form-control error" : "form-control"}  id="inputEmail" name="email" onChange={handleChange}/>
                        <span className='error'>{error.email_err}</span>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="inputPassword4" className="form-label">Password</label>
                        <div className="om-input-group">
                          <input type={passwordType} className={error.email_err ? "form-control error" : "form-control"} id="inputPassword4" name="password" onChange={handleChange} />
                            <span className="om-input-icon" onClick={()=>{setPasswordType(passwordType==="password"?"text":"password")}}>
                              {passwordType==="password" ?
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </g>
                                </svg>
                                :
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M14.2058 7.94718C13.9797 7.46366 13.6958 7.00372 13.3564 6.5781C12.0571 4.9489 10.0867 4 8.00288 4C5.91906 4 3.94864 4.9489 2.6494 6.5781C2.3099 7.00382 2.02588 7.4639 1.7998 7.94754"
                                    stroke="#9496A1" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path
                                    d="M14.2058 7.94702C13.9797 8.43068 13.6957 8.89074 13.3562 9.31646C12.057 10.9457 10.0865 11.8946 8.00272 11.8946C5.9189 11.8946 3.94848 10.9457 2.64924 9.31646C2.30982 8.89084 2.02584 8.4309 1.7998 7.94738"
                                    stroke="#9496A1" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path
                                    d="M7.06498 9.89543C7.65786 10.181 8.34848 10.181 8.94136 9.89543C9.53424 9.60991 9.96486 9.06995 10.1113 8.42839C10.2577 7.78685 10.104 7.11353 9.69374 6.59905C9.28346 6.08457 8.66122 5.78491 8.00316 5.78491C7.34512 5.78491 6.72288 6.08457 6.3126 6.59905C5.9023 7.11353 5.74862 7.78685 5.89506 8.42839C6.04148 9.06995 6.4721 9.60991 7.06498 9.89543Z"
                                    stroke="#9496A1" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path d="M3.20312 12.7999L12.8031 3.19995" stroke="#9496A1"
                                    strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              }
                            </span>
                            <span className='error'>{error.password_err}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-dark login-btn w-100" onClick={handleSubmit} disabled={Loading}>Sign in</button>
                        <div className="text-end">
                          <Link to="/forgot-password" className="rd-forgot-passlink">Forgot
                            Password?</Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-center">
                          <p className="login-notes">Don’t Have an Account ? <Link to="/sign-up">Create
                            One</Link></p>
                        </div>
                      </div>
                    </form>
                  </div>
                {/* </div> */}
                </ScrollArea>
                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
              </div>
            </div>
            <div className="col col-12 col-md-12 col-lg-6">
              <div className="rd-login-img">
                <img src="assets/images/login_img.png" alt="login-img" className="img-fluid w-100" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Login