import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { OM_API_URL } from './BASE_URL';

const ResetPassWord = () => {
    const [details, setDetails] = useState({ password: '', confirm_password: '', pwdShow: false, cpwdShow: false });
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const nav = useNavigate();
    const location = useLocation();
    const email = location.state;

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"

        } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(details.password)) {
            isValid = false;
            err['password_err'] = "Password must be minimum eight characters, at least one letter, one number and one special character";
        }

        if (!details.confirm_password) {
            isValid = false;
            err['confirm_password_err'] = "Please enter confirm password"
        }
        else if(details.password!==details.confirm_password){
            isValid = false;
            err['confirm_password_err'] = "Password does not match"
        }


        setError(err);
        return isValid
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            bodyFormData.append('newpassword', details.password);
            axios({
                method: 'post',
                url: `${OM_API_URL}/guru/reset-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.data.status === true) {
                    nav('/login')
                    toast.success("Password Changed Successfully...")
                    setDisable(false);
                }
            }).catch((err) => {
                toast.error(err.response.data.message)
                setDisable(false);
            })
        }
    }
  return (
    <div>
        <main className="rd-site-content">
        <section className="rd-login-section">
            <div className="row g-0">
                <div className="col col-12 col-md-12 col-lg-6">
                    <div className="rd-login-content">
                        <a href="index.html" className="rd-login-logo">
                            <span className="rd-login-logo__img">
                                <img src="assets/images/logo.svg" alt="logo" className="img-fluid om-logo" /> 
                            </span>
                        </a>
                        <div className="rd-login-form-content" data-scrollbar>
                            <div className="rd-login-form-heading">
                                <h1 className="rd-login-form-title">Reset password</h1>
                                <p className="rd-login-form-description">Enter New Password for login</p>
                            </div>
                            <div className="rd-login-form">
                                <form className="row g-0" autocomplete="off" action="dashboard.html">
                                    <div className="col-md-12">
                                        <label for="inputUsername" className="form-label">New Password</label>
                                        <div className="om-input-group">
                                            <input type={details.pwdShow ?  "text" : "password"} className="form-control" id="inputUsername" name="password" onChange={handleChange} value={details.password} />
                                            
                                            <span className="om-input-icon" onClick={()=>{setDetails({ ...details, pwdShow: !details.pwdShow })}}>
                                                {!details.pwdShow ?
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </g>
                                                    </svg>
                                                    :
                                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M14.2058 7.94718C13.9797 7.46366 13.6958 7.00372 13.3564 6.5781C12.0571 4.9489 10.0867 4 8.00288 4C5.91906 4 3.94864 4.9489 2.6494 6.5781C2.3099 7.00382 2.02588 7.4639 1.7998 7.94754"
                                                        stroke="#9496A1" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                    <path
                                                        d="M14.2058 7.94702C13.9797 8.43068 13.6957 8.89074 13.3562 9.31646C12.057 10.9457 10.0865 11.8946 8.00272 11.8946C5.9189 11.8946 3.94848 10.9457 2.64924 9.31646C2.30982 8.89084 2.02584 8.4309 1.7998 7.94738"
                                                        stroke="#9496A1" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                    <path
                                                        d="M7.06498 9.89543C7.65786 10.181 8.34848 10.181 8.94136 9.89543C9.53424 9.60991 9.96486 9.06995 10.1113 8.42839C10.2577 7.78685 10.104 7.11353 9.69374 6.59905C9.28346 6.08457 8.66122 5.78491 8.00316 5.78491C7.34512 5.78491 6.72288 6.08457 6.3126 6.59905C5.9023 7.11353 5.74862 7.78685 5.89506 8.42839C6.04148 9.06995 6.4721 9.60991 7.06498 9.89543Z"
                                                        stroke="#9496A1" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                    <path d="M3.20312 12.7999L12.8031 3.19995" stroke="#9496A1"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                }
                                            </span>
                                            
                                            <span className='error'>{error.password_err}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label for="inputPassword4" className="form-label">Reenter new Password</label>
                                        <div className="om-input-group">
                                        <input type={details.cpwdShow ? "text" : "password"} className="form-control" id="inputPassword4" name="confirm_password" onChange={handleChange} value={details.confirm_password} />
                                        
                                        <span className="om-input-icon" onClick={()=>{setDetails({ ...details, cpwdShow: !details.cpwdShow })}}>
                                            {!details.cpwdShow ?
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </g>
                                                </svg>
                                                :
                                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.2058 7.94718C13.9797 7.46366 13.6958 7.00372 13.3564 6.5781C12.0571 4.9489 10.0867 4 8.00288 4C5.91906 4 3.94864 4.9489 2.6494 6.5781C2.3099 7.00382 2.02588 7.4639 1.7998 7.94754"
                                                    stroke="#9496A1" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path
                                                    d="M14.2058 7.94702C13.9797 8.43068 13.6957 8.89074 13.3562 9.31646C12.057 10.9457 10.0865 11.8946 8.00272 11.8946C5.9189 11.8946 3.94848 10.9457 2.64924 9.31646C2.30982 8.89084 2.02584 8.4309 1.7998 7.94738"
                                                    stroke="#9496A1" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path
                                                    d="M7.06498 9.89543C7.65786 10.181 8.34848 10.181 8.94136 9.89543C9.53424 9.60991 9.96486 9.06995 10.1113 8.42839C10.2577 7.78685 10.104 7.11353 9.69374 6.59905C9.28346 6.08457 8.66122 5.78491 8.00316 5.78491C7.34512 5.78491 6.72288 6.08457 6.3126 6.59905C5.9023 7.11353 5.74862 7.78685 5.89506 8.42839C6.04148 9.06995 6.4721 9.60991 7.06498 9.89543Z"
                                                    stroke="#9496A1" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M3.20312 12.7999L12.8031 3.19995" stroke="#9496A1"
                                                    strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                            </span>
                                        <span className='error'>{error.confirm_password_err}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="button" onClick={handleChangePassword} className="btn btn-dark login-btn w-100" disabled={disable}>Change Password</button>
                                    </div>

                                    <div className="col-12">
                                        <div className="text-center">
                                        <p className="login-notes">Back to <Link to="/login">Sign
                                            In</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
                    </div>
                </div>
                <div className="col col-12 col-md-12 col-lg-6">
                    <div className="rd-login-img">
                        <img src="assets/images/login_img.png" alt="login-img" className="img-fluid w-100" />
                    </div>
                </div>
            </div>
        </section>
    </main>
    </div>
  )
}

export default ResetPassWord