import React, { useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import Welcome from './Welcome'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { OM_API_URL } from './BASE_URL'
import { toast } from 'react-hot-toast'
const PastEvent = () => {
    const nav = useNavigate();
    const [Loading,setLoading]=useState(true);
    const [LoadMore,setLoadMore]=useState(false);
    const [outoScroll,setOutoScroll]=useState(true);
    const [events,setEvents]=useState([]);
    const [limit,]=useState(15);
    const [offset,setOffset]=useState(0);

    const listInnerRef = useRef();

    const onScroll = () => {
        console.log(listInnerRef.current)
        if(outoScroll){

            if (listInnerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                console.log(scrollTop , clientHeight ,scrollHeight)
                if (scrollTop + clientHeight >= scrollHeight  - 50) {
                    // TO SOMETHING HERE
                    setLoadMore(true)
                    console.log('Reached bottom')
                }
            }
        }
    };

    useEffect(()=>{
        getPastEvents()
    // eslint-disable-next-line
    },[LoadMore]);
    const getPastEvents = () =>{
        axios({
            method: 'post',
            url: `${OM_API_URL}/guru/get-past-events`,
            data:{offset:offset,limit:limit},
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',Authorization:`Bearer ${localStorage.getItem('OM_GURU_TOKEN')}` }
        }).then((response) => {
            if(response.data.status){
                // setEvents([...events,response.data.data]);
                setEvents(prevArray => [...prevArray, ...response.data.data])
                setOffset(offset+limit);
                if(response.data.data.length < limit){
                    setOutoScroll(false);
                }
            }
            else{
                toast.error(response.data.message)
            }
            setLoading(false)
            setLoadMore(false)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
            if(err.response.status===401){
                localStorage.removeItem('OM_GURU_TOKEN');
                localStorage.removeItem('OM_GURU_NAME');
                localStorage.removeItem('OM_GURU_EMAIL');
                localStorage.removeItem('OM_GURU_IMAGE');
                nav('/login');
                setLoadMore(false)
            } 
            // setError({ ...error, password_err: err.response.data.message })
        })
        
    }

  return (
    <div>
         <div className="rd-dashboard-content">
        <Sidebar />
        <main className="rd-site-content">
            <div className="rd-site-content__inner">
                
                <Welcome />
                <div className="rd-page-content" data-scrollbar onScroll={() => onScroll()} ref={listInnerRef}>
                    <div className="rd-customer-heading">
                        <div className="rd-heading-btn">
                            <Link to="/upcoming-events" className="btn btn-outline-dark">Upcoming events</Link>
                            <Link to="/past-events" className="btn btn-dark">Past Events</Link>
                        </div>
                    </div>
                    <div className="om-event-conetnt">
                        <div className="row">
                            {
                            Loading ? 
                            <h3 style={{textAlign:"center"}}>Loading...</h3>
                            :
                            events.map((event)=>{
                                console.log(event)
                                return(
                                <div className="col col-12 col-md-4 col-lg-4">
                                <div className="om-event-card">
                                    <div className="om-event-card-img">
                                        <img src={event.image} alt="Event" className="img-fluid"  onError={({currentTarget})=>{currentTarget.onerror=null;currentTarget.src = '/assets/images/event-img.png'}}/>
                                    </div>
                                    <div className="om-event-card-body">
                                        
                                        <div className="om-event-card-subheading">
                                            <h2 className="om-event-card-title">{event.program_title}</h2>
                                            <ul className="om-event-card-detail">
                                                <li className="om-event-card-detail-item">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.33325 4.06531C5.05992 4.06531 4.83325 3.83864 4.83325 3.56531V1.56531C4.83325 1.29197 5.05992 1.06531 5.33325 1.06531C5.60659 1.06531 5.83325 1.29197 5.83325 1.56531V3.56531C5.83325 3.83864 5.60659 4.06531 5.33325 4.06531Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M10.6667 4.06531C10.3934 4.06531 10.1667 3.83864 10.1667 3.56531V1.56531C10.1667 1.29197 10.3934 1.06531 10.6667 1.06531C10.9401 1.06531 11.1667 1.29197 11.1667 1.56531V3.56531C11.1667 3.83864 10.9401 4.06531 10.6667 4.06531Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M5.66667 9.8986C5.58 9.8986 5.49333 9.87861 5.41333 9.84528C5.32667 9.81195 5.26 9.76527 5.19333 9.70527C5.07333 9.5786 5 9.41194 5 9.23194C5 9.14527 5.02 9.0586 5.05333 8.9786C5.08667 8.8986 5.13333 8.82528 5.19333 8.75861C5.26 8.69861 5.32667 8.65193 5.41333 8.6186C5.65333 8.5186 5.95333 8.57194 6.14 8.75861C6.26 8.88528 6.33333 9.0586 6.33333 9.23194C6.33333 9.27194 6.32667 9.31861 6.32 9.36528C6.31333 9.40528 6.3 9.44527 6.28 9.48527C6.26667 9.52527 6.24667 9.56527 6.22 9.60527C6.2 9.6386 6.16667 9.67193 6.14 9.70527C6.01333 9.82527 5.84 9.8986 5.66667 9.8986Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M7.99992 9.89864C7.91325 9.89864 7.82658 9.87865 7.74658 9.84531C7.65992 9.81198 7.59325 9.7653 7.52659 9.7053C7.40659 9.57863 7.33325 9.41197 7.33325 9.23197C7.33325 9.1453 7.35325 9.05863 7.38659 8.97863C7.41992 8.89863 7.46659 8.82531 7.52659 8.75864C7.59325 8.69864 7.65992 8.65196 7.74658 8.61863C7.98658 8.51196 8.28658 8.57198 8.47325 8.75864C8.59325 8.88531 8.66659 9.05864 8.66659 9.23197C8.66659 9.27197 8.65992 9.31865 8.65325 9.36531C8.64659 9.40531 8.63325 9.44531 8.61325 9.48531C8.59992 9.52531 8.57992 9.5653 8.55325 9.6053C8.53325 9.63864 8.49992 9.67197 8.47325 9.7053C8.34658 9.8253 8.17325 9.89864 7.99992 9.89864Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M10.3334 9.89864C10.2467 9.89864 10.1601 9.87865 10.0801 9.84531C9.99341 9.81198 9.92675 9.7653 9.86008 9.7053C9.83342 9.67197 9.80675 9.63864 9.78008 9.6053C9.75341 9.5653 9.73342 9.52531 9.72008 9.48531C9.70008 9.44531 9.68675 9.40531 9.68008 9.36531C9.67342 9.31865 9.66675 9.27197 9.66675 9.23197C9.66675 9.05864 9.74008 8.88531 9.86008 8.75864C9.92675 8.69864 9.99341 8.65196 10.0801 8.61863C10.3267 8.51196 10.6201 8.57198 10.8067 8.75864C10.9267 8.88531 11.0001 9.05864 11.0001 9.23197C11.0001 9.27197 10.9934 9.31865 10.9867 9.36531C10.9801 9.40531 10.9667 9.44531 10.9467 9.48531C10.9334 9.52531 10.9134 9.5653 10.8867 9.6053C10.8667 9.63864 10.8334 9.67197 10.8067 9.7053C10.6801 9.8253 10.5067 9.89864 10.3334 9.89864Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M5.66667 12.232C5.58 12.232 5.49333 12.212 5.41333 12.1787C5.33333 12.1453 5.26 12.0986 5.19333 12.0386C5.07333 11.912 5 11.7386 5 11.5653C5 11.4786 5.02 11.392 5.05333 11.312C5.08667 11.2253 5.13333 11.152 5.19333 11.092C5.44 10.8453 5.89333 10.8453 6.14 11.092C6.26 11.2186 6.33333 11.392 6.33333 11.5653C6.33333 11.7386 6.26 11.912 6.14 12.0386C6.01333 12.1586 5.84 12.232 5.66667 12.232Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M7.99992 12.232C7.82659 12.232 7.65325 12.1586 7.52659 12.0386C7.40659 11.912 7.33325 11.7386 7.33325 11.5653C7.33325 11.4786 7.35325 11.392 7.38659 11.312C7.41992 11.2253 7.46659 11.152 7.52659 11.092C7.77325 10.8453 8.22658 10.8453 8.47325 11.092C8.53325 11.152 8.57992 11.2253 8.61325 11.312C8.64658 11.392 8.66659 11.4786 8.66659 11.5653C8.66659 11.7386 8.59325 11.912 8.47325 12.0386C8.34658 12.1586 8.17325 12.232 7.99992 12.232Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M10.3334 12.232C10.1601 12.232 9.98675 12.1587 9.86008 12.0387C9.80008 11.9787 9.75342 11.9053 9.72008 11.8187C9.68675 11.7387 9.66675 11.652 9.66675 11.5653C9.66675 11.4787 9.68675 11.392 9.72008 11.312C9.75342 11.2253 9.80008 11.152 9.86008 11.092C10.0134 10.9387 10.2467 10.8653 10.4601 10.912C10.5067 10.9187 10.5467 10.932 10.5867 10.952C10.6267 10.9653 10.6667 10.9853 10.7067 11.012C10.7401 11.032 10.7734 11.0653 10.8067 11.092C10.9267 11.2187 11.0001 11.392 11.0001 11.5653C11.0001 11.7387 10.9267 11.912 10.8067 12.0387C10.6801 12.1587 10.5067 12.232 10.3334 12.232Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M13.6666 6.79187H2.33325C2.05992 6.79187 1.83325 6.5652 1.83325 6.29187C1.83325 6.01854 2.05992 5.79187 2.33325 5.79187H13.6666C13.9399 5.79187 14.1666 6.01854 14.1666 6.29187C14.1666 6.5652 13.9399 6.79187 13.6666 6.79187Z"
                                                            fill="#212121" />
                                                        <path
                                                            d="M10.6667 15.3986H5.33333C2.9 15.3986 1.5 13.9986 1.5 11.5653V5.89864C1.5 3.46531 2.9 2.06531 5.33333 2.06531H10.6667C13.1 2.06531 14.5 3.46531 14.5 5.89864V11.5653C14.5 13.9986 13.1 15.3986 10.6667 15.3986ZM5.33333 3.06531C3.42667 3.06531 2.5 3.99197 2.5 5.89864V11.5653C2.5 13.472 3.42667 14.3986 5.33333 14.3986H10.6667C12.5733 14.3986 13.5 13.472 13.5 11.5653V5.89864C13.5 3.99197 12.5733 3.06531 10.6667 3.06531H5.33333Z"
                                                            fill="#212121" />
                                                    </svg>
                                                    <span>{event.program_date_time.split(', ')[0]}</span>
                                                </li>
                                                <li className="om-event-card-detail-item">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7.99992 15.3986C4.04659 15.3986 0.833252 12.1853 0.833252 8.23197C0.833252 4.27864 4.04659 1.06531 7.99992 1.06531C11.9533 1.06531 15.1666 4.27864 15.1666 8.23197C15.1666 12.1853 11.9533 15.3986 7.99992 15.3986ZM7.99992 2.06531C4.59992 2.06531 1.83325 4.83197 1.83325 8.23197C1.83325 11.632 4.59992 14.3986 7.99992 14.3986C11.3999 14.3986 14.1666 11.632 14.1666 8.23197C14.1666 4.83197 11.3999 2.06531 7.99992 2.06531Z"
                                                            fill="#292D32" />
                                                        <path
                                                            d="M10.4733 10.852C10.3867 10.852 10.3 10.832 10.22 10.7786L8.15334 9.54531C7.64001 9.23865 7.26001 8.56531 7.26001 7.97198V5.23865C7.26001 4.96531 7.48668 4.73865 7.76001 4.73865C8.03334 4.73865 8.26001 4.96531 8.26001 5.23865V7.97198C8.26001 8.21198 8.46001 8.56531 8.66668 8.68531L10.7333 9.91865C10.9733 10.0586 11.0467 10.3653 10.9067 10.6053C10.8067 10.7653 10.64 10.852 10.4733 10.852Z"
                                                            fill="#292D32" />
                                                    </svg>

                                                    <span>{event.program_date_time.split(', ')[1]} - {event.program_end_date_time.split(', ')[1]}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="om-event-card-description">
                                            <p>{event.description}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                                )
                            })
                            
                            }
                            {LoadMore ?<h3 style={{textAlign:"center"}}>Loading...</h3> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    </div>
  )
}

export default PastEvent